import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  "services": [
    { title: "SEO", link: "/SEO-Services-in-Hyderabad" },
    { title: "SMM", link: "/Social-Media-Marketing-agency-in-India" },
    { title: "PPC", link: "/PPC-Services-India" }
  ]
};

const allData = [
  {
    id: 1,
    text: (<>
      <h6>Listen</h6>
      <p>Social media can be a great way to keep up with current trends and see what people are talking about. Listening to what people are saying can give you valuable insights that you can use to improve.</p>
    </>)
  },
  {
    id: 2,
    text: (<>
      <h6>Plan</h6>
      <p>A social media plan is key to the success of any business. You can create a cohesive plan to guide your social media marketing efforts by mapping your goals, content, and strategies.</p>
    </>)
  },
  {
    id: 3,
    text: (<>
      <h6>Execute</h6>
      <p>Social media can be a great way to connect with customers and get feedback about your product. However, it's important to remember that you're ultimately responsible for executing the decisions that you make about your product.</p>
    </>)
  },
  {
    id: 4,
    text: (<>
      <h6>Optimize</h6>
      <p>It can be tough to win on social media. Much content is being shared daily, and it can be hard to get your voice heard. One way to (Search engine optimization) optimize your social media presence i by working with other people who either specialize or happen to be experts in specific areas related to social media.</p>
    </>)
  },
  {
    id: 5,
    text: (<>
      <h5>Faceboook Marketing</h5>
      <p>At Origin Softwares, our Facebook marketing experts will help businesses increase awareness, get more customers, and build a brand that people will love. Our unique strategies will help you achieve business success.</p>
    </>)
  },
  {
    id: 6,
    text: (<>
      <h5>Instagram Marketing</h5>
      <p>As one of the most engaging social media platforms, Instagram is a powerful tool for marketing your brand. Our Instagram marketing services can help you attract followers, increase engagement, and make your account more visible.</p>
    </>)
  },
  {
    id: 7,
    text: (<>
      <h5>Twitter Marketing</h5>
      <p>Twitter marketing can be a great way to connect with your target audience and promote your business. As a leading social media marketing company, we can help you create unique Twitter marketing campaigns that will get results.</p>
    </>)
  },
  {
    id: 8,
    text: (<>
      <h5>Pinterest Marketing</h5>
      <p>Pinterest is a powerful marketing tool for businesses. Our Pinterest marketing services will help drive traffic to your website, boost sales, and attract people with diverse interests.</p>
    </>)
  },
  {
    id: 9,
    text: (<>
      <h5>LinkedIn Marketing</h5>
      <p>LinkedIn is a powerful platform that can help connect you with other industry professionals or similar interests. Our team can help you integrate LinkedIn marketing strategies with your advertising efforts to generate more leads and sales.</p>
    </>)
  },
  {
    id: 10,
    text: (<>
      <h5>YouTube Marketing</h5>
      <p>The power of video marketing is on the rise, and YouTube Marketing is at the forefront. Our team provides services in various aspects of YouTube marketing, from producing online videos to optimizing and advertising them.</p>
    </>)
  },
  {
    id: 11,
    text: (<>
      <h5>Native advertising</h5>
      <span style={{ fontWeight: "bold" }}>What is native advertising?</span><br /> Native advertising is a form of online advertising focused on blending in with the surrounding content. Native ads are often less intrusive than other online advertising, and as a result, they can be more effective.
    </>)
  },
  {
    id: 12,
    text: (<>
      <h5>Ad Platforms</h5>
      Ad platforms can be a great way to get your product in front of a larger audience. However, they can also be much work. You must ensure that your ad platform is well organized and that you monitor your campaigns closely.
    </>)
  }
]

const SMM = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>
            Social media marketing agency in India | Origin software
          </title>
          <link
            rel="canonical"
            href="./Social-Media-Marketing-agency-in-India"
          />
          <meta
            name="description"
            content="Social media marketing agency in India:Origin software offers a wide range of services when it comes to SMM along with strategic planning."
          />
          <meta
            name="keywords"
            content="social media marketing agency in india"
          />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Digital Marketing"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/SEO-Services-in-Hyderabad"}>SEO</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Social-Media-Marketing-agency-in-India"}>SMM</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/PPC-Services-India"}>PPC</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Social Media Marketing</h1>
                  <img className="img mb--20" src="../images/Slider/SMM.png" alt="Social-Media-Marketing-agency-in-India" />
                  <p>Origin Softwares is a social media marketing agency in India that offers solutions to all your SMM problems. We have a team of experienced social media marketers who has a great track record and can help you expand your reach, maximize your web presence, increase traffic, shape your brand image, and connect with influencers.<br /><br />
                    If you're a startup, small business, or white label partner, we can help you take your SMM to the next level.</p>

                  <div className="row mb--20">
                    <h4 className="mb--20">Social Media Process</h4>
                    {allData.slice(0, 4).map(item => (
                      <div className="Box col-xl-6 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className='mb--20'>Technologies we Offer</h3>
                    <div><Technology /></div>
                  </div>

                  <h4 className="mb--20">Build awareness through a distinct voice</h4>
                  <img className="thumbnail" src="../images/InfoGraphics/smm.png" alt="Thumbnail" />

                  <h4 className="mb--20">Full Services: Social Media Marketing</h4>
                  <p>We offer a wide range of Social Media Marketing services which are as follows</p>

                  <div className="row">
                      {jsonData.services.map((services, index) => (
                        <div className=" col-xl-6 col-lg-2">
                            <ul className="b"><li><a href={process.env.PUBLIC_URL + services.link}>{services.title}</a></li></ul>
                        </div>
                      ))}
                    </div>

                  <div className="row mb--20">
                    {allData.slice(4, 10).map(item => (
                      <div className="Box3 col-xl-6 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p className="mb--20">We can help you take your business to the next level with our cutting-edge video marketing solutions.</p>

                  <div className="row mb--20">
                    <div className="paper col-xl-12 col-lg-2">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <h4 className="mb--20">Social Media Marketing - Taking your audience to the next level.</h4>
                          <p>Every business wants to transform its audience into loyal, returning customers.<br />
                            But how do you do that?<br />
                            How do you ensure that your leads become successful?<br />
                            There are a few key things that you can do to nurture your leads and help them succeed.</p>
                          <p><h5 className="mb--20">First, you need to provide value.</h5>
                            It can be in the form of helpful content, educational resources, or even just a friendly ear when they need to vent their frustrations.</p>
                          <p><h5 className="mb--20">Second, you need to be consistent in your follow-up and communications.</h5>
                            It doesn't mean badgering them with emails and phone calls, but keeping the lines of communication open so that they feel comfortable reaching out to you when they're ready to buy.<br />
                            The sales cycle can be long, and it's important not to give up on a lead too soon. If you stick with it, you may find that your loyal audience becomes your most successful leads.</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h3 className='mb--20'>Our Industries</h3>
                    <div><Industries /></div>
                  </div>

                  <h4 className="mb--20"> Full-Funnel Marketing</h4>
                  <p><ul className="b">
                    <li>At its core, full-funnel marketing is about creating a comprehensive and cohesive marketing strategy that takes your potential customers from awareness to purchase.</li>
                    <li>To do this effectively, you need to consider all touchpoints along the customer journey and create content and messaging that is relevant and engaging at each stage.</li>
                    <li>Taking a holistic marketing approach ensures that your campaigns are working together to drive business results.</li>
                  </ul></p>

                  <div className="row">
                    {allData.slice(10, 12).map(item => (
                      <div className="Box col-xl-6 col-lg-6">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Accordion>
                    <h4 className="color">SMM FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="How does social media agency takeover work?"
                      body={<>
                        <ul>
                          ► A social media marketing agency is like having a personal assistant for your business's social media campaign accounts. <br />
                          ► They ensure that everything is up-to-date and looking its best, working on getting the attention of your target audience on all the different social networking websites. <br />
                          ► Among the marketing companies in India, Origin Softwares follows several effective social media strategies to ensure that your brand is present and accounted for on all relevant social media platforms.<br />
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="2"
                      header="How Do We Work as a Social Media Agency?"
                      body={<>
                        <ul>
                          <h6>Tell your brand story</h6>
                          ► Your brand story is what sets you apart from the competition. It's the reason why people should buy from you and not anyone else. A great brand story will make people want to be a part of your company in India and help you build a loyal following.<br /><br />
                          <h6>Develop viral content</h6>
                          ► Creating content that has the potential to go viral can be a great way to increase your reach and engagement. But it's not easy. To develop viral content, you need to understand what makes people share things online.<br />
                          ► It would help if you also created something original and useful that people want to share.<br /><br />
                          <h6>Identify the right platform</h6>
                          ► It's important to identify the right social media platform for your marketing strategy. Not all platforms are created equal some will be more effective than others depending on your product, target audience, and goals.<br />
                          ► We do the research and then make a decision - the success of your marketing campaign depends on it!<br /><br />
                          <h6>Design</h6>
                          ► Designers who create graphics for social media marketing must be aware of current trends in both design and development and marketing.<br />
                          ► We create visually appealing and attention-grabbing designs while also being on brand for the company or product they are promoting.<br />
                          ► Additionally, the graphic's text should be well written and engaging, without being too long or sales.<br />
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="3"
                      header="How much is the cost of social media marketing services?"
                      body={<>
                        <ul>
                          ► The cost of social media management depends on the size of your business and how many platforms you use. There are a lot of different factors that come into play when trying to calculate the perfect social media budget for your company.
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="4"
                      header="Do you offer graphic design services?"
                      body={<>
                        <ul>
                          ► Yes, we offer graphic design services.
                        </ul>
                      </>}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default SMM;
// 480