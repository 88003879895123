import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import AboutThree from "../component/about/AboutThree";
import AboutFour from "../component/about/AboutFour";
import TeamOne from "../component/team/TeamOne";
import { Helmet } from "react-helmet-async";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AboutUs = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>About us | Origin software</title>
          <link rel="canonical" href="./AboutUs" />
          <meta
            name="description"
            content="We specialize in custom software development, mobile application development, and web development for businesses of all sizes."
          />
          <meta name="keywords" content="About us" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher/>
          <BcrumbBannerOne
            title="Origin is the most Incredible Software Company"
            paragraph="We specialize in custom software, mobile, and web development for businesses of all sizes."
            styleClass="large-thumb-3"
            mainThumb="/images/about/3dbanner.png"
          />
          <AboutThree />
          <AboutFour />
          <TeamOne/>
          <CtaLayoutOne />
          <FooterOne parentClass="" />
          {/* <AboutFive /> */}
        </main>
      </div>
    </>
  );
};

export default AboutUs;
