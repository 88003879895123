import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import GetAQuoteForm from '../component/contact/GetAQuoteForm';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import GoogleTagManager from './GTM';

const GetAQuote = () => {
    <GoogleTagManager gtmId="GTM-WV4R3LC" />
    return (
        <>
            <SEO title='Get A Quote' />
            <main className='main-wrapper'>
                <HeaderOne />
                <ColorSwitcher/>
                <div className='section bg-color-light'>
                    <section className="single-portfolio-area">
                        <BreadCrumbOne title="Get A Quote" />
                        <div className="container pad">
                            <div className="row">
                                <GetAQuoteForm/>
                            </div>
                        </div>
                    </section>
                </div>
                <FooterOne/>
            </main >
        </>
    )
}

export default GetAQuote