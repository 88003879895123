import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};


const Recruitment = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>
            Recruitment Services | Reliable and Cost-effective | Origin Software
          </title>
          <link
            rel="canonical"
            href="https://www.originsoftwares.com/Recruitment-Services"
          />
          <meta
            name="description"
            content="Origin Software is a leading recruitment services company. We have been in the industry for many years and have created a vast network of IT professionals."
          />
          <meta name="keywords" content="RECRUITMENT SERVICES" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="IT Staffing"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Recruitment-Services"}> Recruitment</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Staffing-Resources"}>Resourses</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Contract-Staffing-Services"}> Contract Staffing</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Direct-Hire"}> Direct Hire</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Executive-Search"}> Executive Search</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Offshore-Staffing"} > Offshore Staffing</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Recruitment Services</h1>
                  <img className="img mb--20" src="../images/Slider/Recruitment.png" alt="Recruitment-Services" />
                  <p>Origin Softwares is a leading professional recruitment agency. We specialize in the recruitment of permanent, contract, and temporary positions, and work with some of the world's top companies. <br/><br/>
                    Our quality of service is demonstrated by the fact that over 90% of our current business comes from referrals and repeat clients. If you're currently recruiting, looking for advice on industry recruitment trends, or simply want to source information on expected salary rates, we can help.<br/>
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link><br/>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default Recruitment;
// 105