import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const allData = [
  {
    id: 1,
    text: (<>
      <h5>B2B/B2E Apps </h5>
      <p>B2B/B2E apps are designed for internal use by a company's managers and employees. These apps can be used by employees who work in-house or in the field.</p>
    </>)
  },
  {
    id: 2,
    text: (<>
      <h5>B2C Apps </h5>
      <p>B2C apps are designed for customer use. These apps can be used to promote your brand and generate profit.</p>
    </>)
  },
  {
    id: 3,
    text: (<>
      <h5>Challenges We Solve</h5>
      <p>We solve all sorts of development challenges, big and small. From making sure your app is compatible with multiple devices, to addressing performance and memory limitations, to taking measures to reduce battery consumption, we've got you covered. Plus, our Quality Management System guarantees that all challenges are solved properly, so you can be confident you're getting a perfect mobile solution that will work great on any iPhone or iPad.</p>
    </>)
  },
  {
    id: 4,
    text: (<>
      <h5>UI/UX Design</h5>
      <p>At Origin Softwares, we are always up-to-date with Apple's visual design guidelines. This makes it easy for us to meet their expectations. Our mobile app designers use UIKit to create interfaces that comply with Apple's UI standards. Our iOS apps feature transparent interaction patterns that guarantee a seamless user experience. We tune up navigation, feedback, authentication, and other UX functionalities until interacting with the app feels natural.</p>
    </>)
  },
  {
    id: 5,
    text: (<>
      <h5>Technologies and Features</h5>
      <p>At Origin Softwares, we use the latest and greatest technologies available to us to create cutting-edge iOS apps. We take advantage of Objective-C and Swift, as well as introduce all the latest iOS features to our apps. Some of the features we utilize include Apple Pay, iCloud Drive, iBeacon, Core Bluetooth, NFC, Siri, iMovie, 3D Touch, and Social Media APIs. And this list is constantly expanding as iPhones and iPads welcome new possibilities.</p>
    </>)
  },
  {
    id: 6,
    text: (<>
      <h5>Backend</h5>
      <p>When it comes to backend development, our experts in server and database technologies work closely with our mobile app development team to develop a light weight mobile app.</p>
    </>)
  },
  {
    id: 7,
    text: (<>
      <h5>Enterprise Integration</h5>
      <p>Our company specializes in integrating iOS apps with any in-house system you may have. We use the latest mobile device management technologies to ensure enterprise security, widely available connectivity, and efficient app distribution.</p>
    </>)
  },
  {
    id: 8,
    text: (<>
      <h5>Information Security</h5>
      <p>Security is important to us, and we're certified to prove it. We're ISO 27001 certified, which means we follow the highest standards when it comes to keeping your data safe. Whether it's health information or app payments, we use the latest security measures to ensure your data is always protected.</p>
    </>)
  }
]

const IOSApps = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>iOS App Development Services | Origin software</title>
          <link rel="canonical" href="./IOS-App-Development-Services" />
          <meta
            name="description"
            content="Here at Origin Software, we provide leading iOS App Development Services that help businesses of all types to thrive."
          />
          <meta name="keywords" content="ios app development services" />
        </Helmet>
        <main className="main wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Technologies"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Java-Development-Company"}>Java</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Node-Js-Development-Services"}>Node.Js</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Hire-Python-Developers"}>Python</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/IOS-App-Development-Services"}>iOS</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Andriod-App-Development-Services"}>Android</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Angular-Development-Services"}>Angular</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Flutter-App-Development-Company"}>Flutter</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Dot-Net-Application-development"}>.Net</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Php-Development-Services"}>PHP</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/React-js-development-services"}>React.Js</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Magento-Development-Services"}>Magento</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/WordPress-Development-Company-in-India"}>WordPress</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">iOS App Development</h1>
                  <img className="img mb--20" src="../images/Slider/iOS App Development.png" alt="IOS-App-Development-Services" />
                  <p>At Origin Softwares, we provide iOS app development services that include UX and UI design, implementation, QA, and integration of applications for iPhones and iPads. With 9 years of experience in the industry, we have a wealth of expertise across multiple industries.</p>

                  <h4 className="mb--20">Our iOS Project Portfolio</h4>
                  <p>Our iOS project portfolio includes apps for MTV, BBC, Fox Entertainment, T-Mobile, and Orange, We were also trusted to deliver an iOS app for a global market leader in image processing.<br />
                    <Link to={process.env.PUBLIC_URL + "/Portfolio"} className="axil-btn btn-fill-primary btn-small"> Portfolio</Link> </p>

                  <h4 className="mb--20">iOS Apps We Develop</h4>
                  <p>There are two main types of mobile enterprise apps: internal use by a company's managers and employees, and those for customer use.</p>

                  <div className="row">
                    {allData.slice(0, 2).map(item => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className='mb--20'>Technologies we Offer</h3>
                    <div><Technology /></div>
                  </div>

                  <div className="row mb--20">
                    {allData.slice(2, 8).map(item => (
                      <div className="Box3 col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className='mb--20'>Our Industries</h3>
                    <div><Industries /></div>
                  </div>

                  <h4 className="mb--20">Get A Quote</h4>
                  <p>Our team of iOS app development service experts are always available to discuss your app idea or existing project. We can provide professional assistance and help you take your project to the next level.<br />
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small" >Get A Quote</Link></p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};
export default IOSApps;
// 286