import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const ContractStaffing = () => {
<GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>
            Contract staffing services for your businesses | Origin Software
          </title>
          <link
            rel="canonical"
            href="https://www.originsoftwares.com//Contract-Staffing-Services"
          />
          <meta
            name="description"
            content="Origin Software is a full-service contract staffing company. We help businesses find, hire and manage contract and employees for any business process. "
          />
          <meta name="keywords" content="CONTRACT STAFFING SERVICES" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher/>
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                  <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="IT Staffing"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Recruitment-Services"}> Recruitment</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Staffing-Resources"}>Resourses</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Contract-Staffing-Services"}> Contract Staffing</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Direct-Hire"}> Direct Hire</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Executive-Search"}> Executive Search</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Offshore-Staffing"} > Offshore Staffing</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Contract Staffing</h1>
                  <img className="img mb--20" src="../images/Slider/Contracting Staffiing.png" alt="Contract-Staffing-Services"/>                  
                  <p>Contract staffing services can be extremely beneficial for businesses of all shapes and sizes. Here at Innov, we provide companies with access to well-trained and qualified candidates who can fill a variety of roles, both temporary and long-term.<br/><br/>
                    We pride ourselves on being able to really understand the specific needs of our clients and then finding the perfect staffing solution to help them build a competent workforce.<br/>
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default ContractStaffing;
// 105