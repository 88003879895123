import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    )
}

const GetAQuoteForm = () => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        const numericInput = event.target.value.replace(/[^0-9+()-]/g, ''); // Remove all non-numeric characters
        setInputValue(numericInput);
    };

    const [result, showresult] = useState(false);
    const form = useRef();


    const sendEmail = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('contact-name', form.current['contact-name'].value);
        formData.append('contact-email', form.current['contact-email'].value);
        formData.append('contact-phone', form.current['contact-phone'].value);
        formData.append('contact-job', form.current['contact-job'].value);
        formData.append('contact-service', form.current['contact-service'].value);
        formData.append('contact-message', form.current['contact-message'].value);


        emailjs.sendForm('service_dwxrdc6', 'template_a1u31xv', form.current, 'wODK5vqYUZuXuBTMp')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        form.current.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <>
            <form ref={form} onSubmit={sendEmail}>
                <div className='row'>
                    <div className='col-xl-6 col-lg-5'>
                        <div>
                            <h5>Personal Details :</h5>
                        </div>

                        <div className="form-group">
                            <label htmlFor="name">Name :</label>
                            <input type="text" id="name" className="form-control" name='contact-name' placeholder='Full Name' required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email :</label>
                            <input type="email" id="email" className="form-control" name='contact-email' placeholder='Email ID' required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone :</label>
                            <input type="tel" id="phone" className="form-control" value={inputValue} onChange={handleInputChange} name='contact-phone' placeholder='Mobile No.' required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="job">Job Title :</label>
                            <input type='text' id="job" className="form-control" name='contact-job' placeholder='Job Title' required></input>
                        </div>
                    </div >
                    <div className='col-xl-6 col-lg-5'>
                        <div>
                            <h5>Project Details :</h5>
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Services :</label>
                            <select className="form-control" name="contact-service" style={{ height: "60px" }} required>
                                <option value="service" disabled selected>Select an option &#9662;</option>
                                <option value="1">Software Development</option>
                                <option value="2">Website Design</option>
                                <option value="3">Web Development</option>
                                <option value="4">E-Commerce Services</option>
                                <option value="5">Mobile Apps</option>
                                <option value="6">Corporate Branding</option>
                                <option value="7">Digital Marketing</option>
                                <option value="8">IT Staffing</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message :</label>
                            <textarea id="message" className="form-control" name='contact-message' placeholder='Your message' style={{ height: "280px" }} required></textarea>
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="axil-btn btn-fill-primary" name="submit-btn">Submit</button>
                    </div>
                    <div className="form-group">
                        {result ? <Result /> : null}
                    </div>
                </div>
            </form >
        </>
    );
}

export default GetAQuoteForm;

