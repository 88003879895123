import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className="color1">{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  )
}

const jsonData = {
  "services": [
    { title: "UI Design", link: "/UI-Design-Services" },
    { title: "UX Design", link: "/UX-Design-Services" },
    { title: "Responsive Web Design", link: "/Responsive-Web-Design-Services" },
    { title: "Responsive Web App Design", link: "/Responsive-Web-Application" },
    { title: "Website Redesign", link: "/Website-Redesign-Services" }
  ]
}

const allData = [
  {
    id: 1,
    text: (
      <>
        <h5>Ecommerce UX design: </h5>
        <p>eCommerce store experience that will greatly increase your average customer lifetime value (clv).</p>
      </>
    )
  },
  {
    id: 2,
    text: (
      <>
        <h5>Web & Mobile UX design: </h5>
        <p>Incredible UX of your app across all devices to increase the number of visitors and their session time.</p>
      </>
    )
  },
  {
    id: 3,
    text: (
      <>
        <h5>SaaS UX design: </h5>
        <p>SaaS user experience that ensures fast onboarding and a high subscription renewal rate.</p>
      </>
    )
  }
]

const UXDesign = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>UX Design Services (User Experience) | Origin software</title>
          <link rel="canonical" href="./UX-Design-Services" />
          <meta
            name="description"
            content="We help you create exceptional digital customer experience and increase customer engagement with our UX design services."
          />
          <meta name="keywords" content="ux design services" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Website Design"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/UI-Design-Services"}>UI Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/UX-Design-Services"}> UX Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Responsive-Web-Design-Services"}> Responsive Web Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Responsive-Web-Application"}> Responsive Web App Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Website-Redesign-Services"}> Website Redesign</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>

                <div className="title-BC col-lg-9">
                  <h1 className="color">
                    UX Design Services (User Experience)
                  </h1>
                  <img className="img mb--20" src="../images/Slider/UX Design.png" alt="UX-Design-Services" />
                  <p>UX design services: User experience design is about more than just making software that looks pretty. It's also about making sure that users have a smooth and easy experience when using the software. </p>
                  <p>Origin Softwares comprehensive approach to UX design means that we take care of everything from user satisfaction to maximizing the use of features.</p>

                  <h4>Our UX Design Service Options</h4>

                  <p> We're experts at crafting amazing user experiences for our clients. Whether you need a one-time service or continuous support, we'll work with you to iteratively improve your UX<br /><br />
                    Some of the areas we specialize in include eCommerce, web and mobile app design, and SaaS. With our help, you can increase your average customer lifetime value, the number of visitors, and the subscription renewal rate.</p>

                  <div className="row mb--20">
                    {allData.slice(0, 3).map(item => (
                      <div className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className='b'><li><a href={process.env.PUBLIC_URL + services.link}>{services.title}</a></li></ul>
                      </div>
                    ))}
                  </div>

                  <h4 className="mb--20">Don’t Lag Behind in the User Experience Race!</h4>
                  <div className="paper">
                    <p>With Origin Softwares experienced team on your side, you're sure to create a great user experience that will keep your customers coming back for more.<br /><br />
                      <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link></p>
                  </div>

                  <div className="row">
                    <h3 className='mb--20'>Our Industries</h3>
                    <div><Industries /></div>
                  </div>
                  <div className="row">
                    <h3 className='mb--20'>Technologies we Offer</h3>
                    <div><Technology /></div>
                  </div>

                  <Accordion>
                    <h4 className="color">UX Design FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header=" Aimed at Achieving High Adoption and Conversion Rate?"
                      body={
                        <>
                          <ul>
                            <p>► Our aim is always to achieve a high adoption and conversion rate, so you can be sure that you're getting the best possible UX design when you work with us.</p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="What UX Services Include?"
                      body={
                        <>
                          <ul>
                            <p>► User research and analysis are important for understanding your audience.<br /><br />
                              ► This can be done through competitor analysis, web analytics, user interviews, and initial user testing.<br /><br />
                              ► From this data, you can create personas and scenarios. persona are fictional profiles of your real users and scenarios are user-software interaction patterns.<br /><br />
                              ► These different scenarios are combined to create a comprehensive user journey map.<br /><br />
                              ► Wireframing and prototyping are two important tools for product managers. By wireframing, you can create a visual representation of your app or website.<br /><br />
                              ► This can be helpful in communicating your vision to others on your team. Prototyping allows you to test your ideas before investing in front-end development. <br /><br />
                              ► By testing your prototypes, you can eliminate potential UX errors and ensure a high-quality UX design.</p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="Why Origin Softwares?"
                      body={
                        <>
                          <ul>
                            <p>► Origin Softwares' 9 years in the IT industry, shows our dedication and expertise in the field. <br /><br />
                              ►Our UX team is experienced and ready to work on your project. <br /><br />
                              ► We believe in rigorous testing to ensure the quality of our work and eliminate any chances of critical errors.</p>
                            <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="4"
                      header="Why UX Design Matter?"
                      body={
                        <>
                          <ul>
                            <p>► We know that users will switch to a competitor if they aren’t satisfied with the current UX, which is why we always start with UX research or audit.<br /><br />
                              ► This allows us to learn what benefits user experience design or redesign can offer to you.<br /><br />
                              ► With our result-driven approach, you can be sure that you’ll see a return on your investment in user experience.<br /><br />
                              ► If your users are unhappy with your product's current UX, there's a 79% chance they'll switch to a competitor. <br /><br />
                              ► You can improve conversion rates by 400% or more by investing in high-quality UX. For every $1 you invest in user experience, you'll see a $100 return.</p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="5"
                      header="What do You get with Our UX Design?"
                      body={
                        <>
                          <ul>
                            <h6>Result-Driven Approach</h6>
                            ► Our approach is always results-driven. We begin with UX research (for software in development) or a UX audit (for developed software) to learn how user experience design or redesign can benefit you. <br /><br />
                            ► We also recommend iterative UX optimization, which allows us to introduce UX improvements and analyze their effect along the way<br /><br />
                            <h6>Maximum User Flow Coverage</h6>
                            ► To make sure that our personas reflect all typical behavior patterns for your audience, we create user scenarios that cover 90%-95% of all possible interaction situations.<br /><br />
                            ► For the remaining ~10%, we conduct additional research during the user journey mapping stage.<br /><br />
                            <h6>UX and UI Design Service Bundle</h6>
                            ► Our UI design and UX design services are closely interconnected and share the objective of facilitating users’ interaction with the software.<br /><br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="6"
                      header="Why You Should Invest in UX Design services?"
                      body={
                        <>
                          <ul>
                            ► If you're part of the 73% of companies that understand that user experience is crucial to success, then you're already ahead of the curve. <br /><br />
                            ► UX design is more important than ever before, and if you choose to ignore that fact, you'll be at a disadvantage compared to your competition.<br /><br />
                            ► Don't let your business lag behind - invest in UX design and see conversions increase sixfold.<br /><br />
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default UXDesign;