import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Industries from "../component/Industries/Industries.js";
import Technology from "../component/Technologies/Technology.js";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  "services": [
    {title: "Software Consulting", link: "/Software-Consulting"},
    {title: "Custom Software Development", link: "/Custom-Software-Development-Company-in-India"},
    {title: "Software Development Outsourcing", link: "/Software-Development-Outsourcing"},
    {title: "Software Product Development", link: "/Software-Product-Development"},
    {title: "Software Development Automation", link: "/Software-Development-Automation"},
    {title: "Software Maintenance and support", link: "/Software-Maintenance-and-Support"}
  ]
};

const allData = [
  {
    id: "1",
    text: (
      <>
        <h6>Software Development: From Plan to Delivering Value</h6>
        There are two main types of software development: enterprises and software products for companies and startups. Each type has its own process and methodology.<br /><br />
        <h6>Choosing an Optimal Pace for Your Project</h6>
        Software product companies and startups usually use scrum development or kanban development. These methods focus on delivering value to the customer as quickly as possible.
      </>
    )
  },
  {
    id: "2",
    text: (
      <>
        <h6>Software Development Methodologies We Employ</h6>
        <p>Enterprises typically use a methodology called agile. In this approach, the software is developed in small portions and new features are delivered on a regular basis.</p>
        This allows for continuous improvement and collaboration between the development team and the client.
      </>
    )
  },
  {
    id: "3",
    text: "Our software development team is experienced in large, complex projects with distributed teams and multiple vendors."
  },
  {
    id: "4",
    text: " We have an in-house project management office (PMO) that oversees all aspects of the software development process, from envisioning and planning to design, developing, integrating, deploying, and supporting software solutions."
  },
  {
    id: "5",
    text: "Our software development services are built around strict KPIs that measure business outcomes, quality of delivered software, code quality, development process efficiency, project time and costs, team productivity, user experience, and user satisfaction."
  },
  {
    id: "6",
    text: (
      <>
        <h6>Fixed price : </h6>
        The fixed price model is best for feasibility studies, proof of concept projects, and small software development projects with well-defined and stable requirements.<br></br>
        With this type of pricing, you pay a set price as determined by a contract.
      </>
    )
  },
  {
    id: "7",
    text: (
      <>
        <h6>Time &amp; Material with a cap : </h6>
        Time and material contracts, as well as time and material contracts with an upper limit, are best for advisory activities such as business processes analysis, architecture design, and project planning. <br></br>
        With this type of pricing, you receive an invoice at the end of each month based on the hours or effort expended during that month (up to the stated limit in the case of time and material contracts with a cap).
      </>
    )
  },
  {
    id: "8",
    text: (
      <>
        <h6>Per-ticket pricing : </h6>
        Per-ticket pricing is best for L1 and L2 application support. With this pricing model, you pay for each incident we resolve.
      </>
    )
  },
  {
    id: "9",
    text: (
      <>
        <h6>Fixed monthly fee :</h6>
        Fixed monthly fees are best for L3 application support, which includes in-code defect fixes and functional changes. With this pricing model, you pay a set monthly fee for the services we provide.
      </>
    )
  }
]

const SoftwareDevelopment = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>Software development company in India | Origin software</title>
          <link
            rel="canonical"
            href="./Software-Development-Company-in-India"
          />
          <meta
            name="description"
            content="Origin Software is the most prominent and trusted software development company in India. We offer high-quality services to global clients."
          />
          <meta
            name="keywords"
            content="software development company in india"
          />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem 
                      eventKey="1"
                      header="Software Development"
                      body={<>
                      <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Consulting"}> Software Consulting</Link></li><br/>
                            <li><Link to={process.env.PUBLIC_URL + "/Custom-Software-Development-Company-in-India"}>Custom Software Development</Link></li><br/>
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Development-Outsourcing"}> Software Development Outsourcing</Link></li><br/>
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Product-Development"}> Software Product Development</Link></li><br/>
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Development-Automation"}> Software Development Automation</Link></li><br/>
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Maintenance-and-Support"}> Software Maintenance and support</Link></li>
                          </ul>
                      </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Software Development</h1>
                  <div>
                    <img className="img mb--20" src="../images/SOFTWARE DEVELOPMENT.png" alt="Software-Development-Company-in-India" /></div><br></br><br></br>
                  <p className="mb--20">Origin Softwares is a top software development company in India, offering a wide range of software development services. Origin Softwares is a renowned name in software development work. we offer services to start-up, small and medium enterprises.</p>
                  <p className="mb--20">Since 9 Years. Origin Softwares has developed incredible software for various industries like retail, banking, school management, etc. We believe in quality rather than quantity which draws our clients in.</p>
                  <p className="mb--20">Origin Softwares Design, develop, and deploy software that meets the needs of businesses and government. </p>
                  <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Get a Quote</Link><br></br><br></br>

                  <img className="thumbnail" src="../images/InfoGraphics/softdev.png" alt="Thumbnail" />

                  <div className="container mt-3">
                    <div className="row">
                      <h4>Software for Different Industries</h4>
                      <p className="mb--20"> Click on the icons below to see some of our most notable projects in the industries that interest you.</p>
                      <div><Industries /></div>
                    </div>
                  </div>

                  <h4>Advanced Business Tasks</h4>
                  <ul className="b">
                    <li>We have successfully completed projects in a variety of industries, including big data, artificial intelligence, theinternet of things, computer vision, augmented reality (AR), virtual reality (VR), and blockchain.</li><br />
                    <li> You can find more information about our company's capabilities on our website.</li></ul>
                  <h4>Incredible Software We developed</h4>
                  <Link to={process.env.PUBLIC_URL + "/Portfolio"} className="axil-btn btn-fill-primary btn-small"> Portfolio</Link>

                  {/* <h4>Partnerships</h4>
                  <p>Add partnerships here</p> */}

                  <h4>Our Clients</h4>
                  <div className="row">
                    <TestimonialTwo />
                  </div>

                  <div className="row">
                    <h4>Software Development Plans</h4>
                    {allData.slice(0, 2).map(item => (
                      <div key={item.id} className="Box col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    <h4>Our highlights on the leading software development company in India </h4>
                    {allData.slice(2, 5).map(item => (
                      <div key={item.id} className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                    <div className="row">
                    <h4 className="mb--20">Our Services</h4>
                    {jsonData.services.map((service, index) => (
                      <div key={index} className="col-xl-6 col-lg-2">
                          <ul className='b'><li><a href={process.env.PUBLIC_URL + service.link}>{service.title}</a></li></ul>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    {allData.slice(5, 9).map(item => (
                      <div key={item.id} className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="container mt-3">
                    <div className="row">
                      <h3 className='mb--20'>Our Software Technologies</h3>
                      <div><Technology /></div>
                    </div>
                  </div>
                  <h4>Our software development process</h4>
                  <img className="thumbnail" src="../images/InfoGraphics/softwareprocess.png" alt="Thumbnail" />
                  <Accordion>
                    <h4 className="color">Software Development FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Why Origin Softwares?"
                      body={
                        <>
                          <ul>
                            <p>► At Origin Softwares, we have a long track record of successful software projects across a wide range of industries. </p>
                            <p>► We develop software solutions that are tailored to the specific needs of our clients in different industries, always keeping an eye on the latest technologies and latest industry trends.</p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header=" What Service Are You Looking For?"
                      body={
                        <>
                          <ul>
                            <p> Select your service and dive into the details of the services.</p>
                            <h6>Custom software development services</h6>
                            <p>► Our company offers custom software solutions that fit your needs. The delivery is fast with excellent software and zero errors.</p>
                            <h6>Software product development</h6>
                            <p>► At Origin Softwares, we pride ourselves on our ability to help startups and ISVs not only envision successful software products but also to launch Minimum Viable Products (MVPs) in a timeframe of 1-4 months.</p>
                            <h6>Development team augmentation</h6>
                            <p>► We have a highly skilled team of software developers and architects who are experienced in the back-end, front-end, web applications, and mobile application development.</p>
                            <p>► we are able to provide a self-managed team for your project.</p>
                            <h6>Software support and maintenance</h6>
                            <p>► We provide support for your current employees &amp; users, constant software performance monitoring, optimization, and quick issue solution. We can also safely add new features and integrations.</p>
                            <h6>Full software development outsourcing</h6>
                            <p>► Our team has extensive experience developing all types of software, including web and mobile apps, cloud applications, SaaS products, desktop applications, and libraries.</p>
                            <p>► Origin Softwares has been working in the IT industry for over 9 years of experience and our team of IT professionals is ready to take on you project.</p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="How We Ensure Quality?"
                      body={
                        <>
                          <ul>
                            <p>At our company, quality is a top priority from the very beginning stages of development all the way through to the final product.<br></br>► We have a quality management system in place that is ISO 9001 certified, and we have various controls and guidelines in place to ensure that our code is of the highest quality.</p>
                            <p>► This includes adhering to a style guide, commenting on complex code, breaking up code into small logical units, and conducting code reviews.</p>
                            <p>► We also use code quality metrics to measure the maintainability and complexity of our code.</p>
                            <p>► By following these practices, we can ensure that our products meet the highest standards of quality.</p>
                            ● All types of testing:<br></br>
                            <p>► At our software testing facility, we provide all different types of testing services including functional, integration, performance, usability, and security.</p>
                            <p>► We recommend that for large projects, customers automate their functional and performance testing in order to get optimal coverage at a lower cost. Our ISO 27001 certification ensures that our customer's information is always kept secure.</p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="4"
                      header="Why contact us?"
                      body={
                        <>
                          <ul>
                            <p>► At our company, we are recognized for the software development domain expertise and support.</p>
                            <p>► We can help you plan and build new software that achieves your business goals while keeping costs low.</p>
                            <p>► We also have experience supporting existing software, so you can be confident that your investment is well-protected.</p>
                            <p>► We also offer cross-platform development, and web apps development services and aim for the digital transformation of small &amp; medium businesses.</p>
                            <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small" > Get a Quote</Link>
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default SoftwareDevelopment;