import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import EducationD from '../data/Portfolio/EducationD.json';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import GoogleTagManager from './GTM';

const allData = EducationD

const Education = () => {
    <GoogleTagManager gtmId="GTM-WV4R3LC" />
    return (
        <>
            <SEO title='Education' />
            <main className='main-wrapper'>
                <HeaderOne />
                <ColorSwitcher/>
                <div className='section bg-color-light'>
                    <BreadCrumbOne title="Education" />

                    <div className='container mt-3'>
                        <div className='row'>
                            {allData.map((data) => (
                                <div className='col-md-3'>
                                <div className='card margin'>
                                    <img src={process.env.PUBLIC_URL + data.image} alt={data.name} />
                                    <div className='card-body'>
                                        <h5>{data.name}</h5>
                                        <a href= {process.env.PUBLIC_URL + data.url} target='_blank' rel="noopener noreferrer"><button className='axil-btn btn-fill-primary btn-fluid btn-primary'>Live Preview</button></a>
                                    </div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
                <FooterOne />
            </main>
        </>
    )
}

export default Education