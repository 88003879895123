import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className="color1">{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  )
}

const jsonData = {
  "services": [
    { title: "Responsive Web Design", link: "/Responsive-Web-Design-Services" },
    { title: "Responsive Web App Design", link: "/Responsive-Web-Application" },
    { title: "Website Redesign", link: "/Website-Redesign-Services" }
  ]
}

const linkData = [
  { title: "Mobile Apps Development", link: "/Mobile-App-Development-Services" },
  { title: "Digital Marketing Services", link: "/Digital-Marketing-Agency-in-Hyderabad" },
  { title: "Custom Soft. Development", link: "/Custom-Software-Development-Company-in-India" },
  { title: "Social Media Marketing", link: "/Social-Media-Marketing-agency-in-India" },
]

const allData = [
  {
    id: 1,
    text: "As a web development company in Hyderabad, India, we always stay ahead of the curve by adopting emerging technology frameworks and engineering standards to build full-stack web applications."
  },
  {
    id: 2,
    text: "We help brands use powerful new technologies to develop innovative cloud-based web applications that improve efficiency and exceed customer expectations."
  },
  {
    id: 3,
    text: "We also use our digital marketing expertise at every step of the web design and development process to empower our clients into powerful interaction avenues for visitors and search engines.."
  },
  {
    id: 4,
    text: "Our web development team focuses on creating custom websites and applications for our clients."
  },
  {
    id: 5,
    text: "We specialize in delivering high-quality, responsive, and user-friendly websites that meet the unique needs of our clients."
  },
  {
    id: 6,
    text: "Our team has a wealth of experience developing CMS websites, eCommerce portals, PWA, and end-end digital product development."
  },
  {
    id: 7,
    text: (<>
      <h5>Integrate Digital Marketing</h5>
      <p>The digital marketing and web development teams work together to create websites that are easy to use and rank highly in search engines.<br />
        Our focus is on design, performance, and search engine compatibility so that users can find what they're looking for easily and search engines can index the site quickly and accurately.</p>
    </>)
  },
  {
    id: 8,
    text: (<>
      <h5>Emerging Technologies </h5>
      <p>Emerging technologies are always changing, and it can be difficult to keep up with the latest and greatest.<br />
        That's where we come in. We help businesses choose the right technology that will complement their business goals, and we're always looking for new technologies that can help them be more agile and scalable.</p>
    </>)
  },
  {
    id: 9,
    text: (<>
      <h5>Mobile First Approach</h5>
      <p>At our web development agency, we make sure to put mobile optimization as a top priority.<br />
        This way, we can avoid any extra complexities and repetition that might come with designing for mutual mobile devices.<br />
        With a mobile-first approach, we can streamline the design process and better focus on the needs of our clients.</p>
    </>)
  },
  {
    id: 10,
    text: (<>
      <h5>Financial Impact</h5>
      <p>At Origin Softwares, we're all about building great web applications and digital products.
        Our goal is to provide our clients with the latest and greatest web technologies at an affordable price so they can scale quickly.<br />
        We love working with startups and try to match our commercial terms to their budgets. It gives them the room they need to unleash their creativity.</p>
    </>)
  },
  {
    id: 11,
    text: (<>
      <h5>Omni Channel Solutions</h5>
      <p>Regarding web development, we're all about mobility and cross-channel presence. We believe that these are integral parts of any great web development project.</p>
    </>)
  },
  {
    id: 12,
    text: (<>
      <h5>Agile Approach</h5>
      <p>The agile approach our web development team takes is highly organized and efficient.<br />
        We constantly communicate with our team members to ensure everyone is on the same page and knows what needs to be done.<br />
        Our team is also nimble and responsive, meaning we can quickly adapt to changes and deliver projects on time.</p>
    </>)
  },
  {
    id: 13,
    text: (<>
      <h5>Discovery & Intelligence</h5>
      <p>The discovery and intelligence stage of our web development process is key to understanding your business needs, finding areas of opportunity, and understanding what your competitors are doing. </p>
    </>)
  },
  {
    id: 14,
    text: (<>
      <h5>Planning & Strategy</h5>
      <p>We use this information to build a deep tech strategy to help you assess your current and future requirements, integrations, and budgets.<br></br>
        Our team then proposes an optimal technology architecture that enhances business efficiency and productivity.</p>
    </>)
  },
  {
    id: 15,
    text: (<>
      <h5>Information Architecture & Design</h5>
      <p>We begin the web development process by defining wireframes, Information architecture (IA), navigations, content flow, UI/UX, and mapping user engagement flows.<br></br>
        It helps us organize and label key features and functionalities to increase the website's average session time and conversion optimization.</p>
    </>)
  },
  {
    id: 16,
    text: (<>
      <h5>Development & Testing</h5>
      <p>Our web development team takes an agile approach to begin executing the plans with the development team.</p>
    </>)
  },
  {
    id: 17,
    text: (<>
      <h5>UI/UX Services</h5>
      <p>Our UI/UX design services focus on creating a complete user experience for mobile apps, web applications, and other digital products.</p>
    </>)
  },
  {
    id: 18,
    text: (<>
      <h5>Responsive Web Design Services</h5>
      <p>We work with clients to understand their digital goals and draw on our digital marketing expertise to develop responsive, user-friendly websites that achieve those goals</p>
    </>)
  }
]

const WebsiteDevelopment = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>
            Best Web Development Company in Hyderabad | Origin software
          </title>
          <link rel="canonical" href="./Web-Development-Company-in-Hyderabad" />
          <meta
            name="description"
            content="We are the best web development company in Hyderabad. Our work is to create an awesome website for your business so that it can reach new heights"
          />
          <meta
            name="keywords"
            content="web development company in Hyderabad"
          />
        </Helmet>
      </div>
      <main className="main-wrapper">
        <HeaderOne />
        <ColorSwitcher />
        <div className="section-padding-equal">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-3 why-choose-us">
                <div className="sidebar-fixed">
                  <Accordion defaultActiveKey="1">
                    <AccordionItem
                      eventKey="1"
                      header="Web Development"
                      body={<>
                        <ul className="a">
                          <li><Link to={process.env.PUBLIC_URL + "/Responsive-Web-Design-Services"}> Responsive Web Design</Link></li><br />
                          <li><Link to={process.env.PUBLIC_URL + "/Responsive-Web-Application"}> Responsive Web App Design</Link></li><br />
                          <li><Link to={process.env.PUBLIC_URL + "/Website-Redesign-Services"}> Website Redesign</Link></li>
                        </ul>
                      </>}
                    />
                  </Accordion>
                </div>
              </div>
              <div className="title-BC col-lg-9">
                <h1 className="color">Web Development Company</h1>
                <img className="img" src="../images/Slider/Web Development Company.png" alt="Web-Development-Company-in-Hyderabad" />

                <div className="row mb--20">
                  <h4>Best Web Development Company in Hyderabad</h4>
                  {allData.slice(0, 6).map(item => (
                    <div className="Box col-xl-4 col-lg-4">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <p>{item.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <h4 className="mb--20">Web development at origin software</h4>
                <ul className="b"><li>The web development team at originsoftwares is experienced in multiple digital disciplines, and we use our knowledge to create beautiful and high-performing web applications.</li>
                  <li>We take what we've learned and apply it throughout the web design and development process, resulting in amazing results for our clients.</li></ul>

                <div className="row">
                  {allData.slice(6, 12).map(item => (
                    <div className="Box3 col-xl-4 col-lg-4">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <p>{item.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row mb--20">
                  <h3>Web Development Services</h3>
                  <h4>Why choose us </h4>
                  <Link to={process.env.PUBLIC_URL + "/Portfolio"} className="axil-btn btn-fill-primary btn-small">Portfolio</Link>
                </div>

                <div className="row">
                  <h3 className='mb--20'>Technologies we Offer</h3>
                  <div><Technology /></div>
                </div>

                <div className="row mb--20">
                  <h4>Web Design & Development Process</h4>
                  {allData.slice(12, 16).map(item => (
                    <div className="Box col-xl-5 col-lg-4">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <p>{item.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row mb--20">
                  <h4 className="mb--20">More Services</h4>
                  {allData.slice(16, 18).map(item => (
                    <div className="Box col-xl-6 col-lg-6">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <p>{item.text}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <img className="thumbnail" src="../images/InfoGraphics/react&node.png" alt="Thumbnail" />
                </div>


                <div className="row">
                  <h3 className='mb--20'>Our Industries</h3>
                  <div><Industries /></div>
                </div>

                <div className="container mt-3">
                  <h4>E-Commerce Development Services</h4>
                  <p>Our eCommerce services consider industry best practices to develop scalable eCommerce websites that can easily integrate into existing systems.</p>
                  <div className="row">
                    {linkData.map((item, index) => (
                      <div className="col-md-3">
                        <div className="Mcard margin">
                          <a href={process.env.PUBLIC_URL + item.link}>{item.title}</a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="row">
                  {jsonData.services.map((services, index) => (
                    <div className=" col-xl-6 col-lg-2">
                      <ul className='b'><li><a href={process.env.PUBLIC_URL + services.link}>{services.title}</a></li></ul>
                    </div>
                  ))}
                </div>

                <Accordion>
                  <h4 className="color">Web Development FAQ's</h4>
                  <AccordionItem
                    eventKey="1"
                    header="Which is the best web development company in Hyderabad?"
                    body={<>
                      <ul>
                        ► There are a lot of great web development companies in Hyderabad. One way to narrow down your options is to look at online reviews and see what others have said about each company.<br></br>
                        ► Another option is to contact each company directly and ask them about their experience and expertise.
                      </ul>
                    </>}
                  />
                  <AccordionItem
                    eventKey="2"
                    header="How long does it take to develop the website?"
                    body={<>
                      <ul>
                        ► We understand that every customer has different needs and timelines. We average six to eight weeks per project, but we are always flexible to work at the pace that best suits our clients.
                      </ul>
                    </>}
                  />
                  <AccordionItem
                    eventKey="3"
                    header="What does the web development process include?"
                    body={<>
                      <ul>
                        ► A modern website is more than just what the user sees on the surface. A lot of work goes on under the hood to make a website function properly.<br></br>
                        ► A good web developer can work across all aspects of website development, from the front-end design to the back-end code.<br></br>
                        ► If we ever come up with a website that doesn't meet your needs, we are more than happy to make changes and adjustments to find a solution that works for you.
                      </ul>
                    </>}
                  />
                  <AccordionItem
                    eventKey="4"
                    header="Do we offer to redesign and restructure the website?"
                    body={<>
                      <ul>
                        ► Having a website that doesn't suit your needs is frustrating. At our company, we specialize in redesigning and restructuring websites to fit our client's needs better.
                      </ul>
                    </>}
                  />
                  <AccordionItem
                    eventKey="5"
                    header="Do we develop a mobile-friendly website?"
                    body={<>
                      <ul>
                        ► More and more users are logging on from tablets and smartphones, so it's crucial that your website can be accessed and navigated easily from any device.<br></br>
                        ► All the websites we create are mobile-friendly, so you can rest assured that your visitors will have a positive experience no matter how they access your site.
                      </ul>
                    </>}
                  />
                  <AccordionItem
                    eventKey="6"
                    header="What kind of services do we offer?"
                    body={<>
                      <ul>
                        ► As a web design and development company, In addition to web design and development, we also offer a variety of other services, including graphic design, logo design, SEO, digital marketing, mobile application development, and content writing. <br></br>
                        ► No matter your needs, we can help you create a website you can be proud of.
                      </ul>
                    </>}
                  />
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <FooterOne />
      </main >
    </>
  );
};

export default WebsiteDevelopment;