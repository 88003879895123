import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const allData = [
  {
    id: 1,
    text: (<>
      <h5>Request Analysis (1 week)</h5>
      <p>We will sit down with you and discuss your development needs to better understand how we can help you. This will allow us to define a cooperation model that best suits your needs (team augmentation, dedicated team, or full outsourcing).</p>
    </>)
  },
  {
    id: 2,
    text: (<>
      <h5>SLA preparation (2 weeks)</h5>
      <p>We will discuss the services you need within the chosen cooperation model and reflect them in a Service Level Agreement.This Agreement will outline the expectations and responsibilities of both parties for the duration of our cooperation</p>
    </>)
  },
  {
    id: 3,
    text: (<>
      <h5>Screening & Interviews (2-5 days)</h5>
      <p>For a team augmentation model, we will provide you with developers’ CVs and set up interviews with the candidates you are interested in.</p>
    </>)
  },
  {
    id: 4,
    text: (<>
      <h5>Cooperation launch (1-2 days)</h5>
      <p>We will have a meeting on service scope between developers and you which was mentioned in SLA</p>
    </>)
  },
  {
    id: 5,
    text: "Our .NET development services are extremely flexible and can be customized to each client's individual needs."
  },
  {
    id: 6,
    text: "We have a team of experienced business analysts who will assess your project requirements and define the scope of work."
  },
  {
    id: 7,
    text: "We also have a dedicated team of project managers who will develop a detailed project plan, including the tech stack, schedule, and budget."
  },
  {
    id: 8,
    text: "At Origin Softwares, our UX/UI design team is focused on delivering mockups that will not only look great but also be easy for users to adopt and understand."
  },
  {
    id: 9,
    text: "Our .NET software development team is equipped with all of the latest tools and technologies to create custom software solutions for our clients."
  },
  {
    id: 10,
    text: "Quality assurance is a process that Origin Softwares XYZ specialists use to guarantee a high level of performance for your software."
  },
  {
    id: 11,
    text: "This operating system is ISO 9001 certified and can be used to test the functional, performance, usability, security, and exploratory aspects of your software."
  },
  {
    id: 12,
    text: "Our IMAAT approach is designed to help you reduce the costs of testing while maintaining a high level of test coverage."
  },
  {
    id: 13,
    text: "We also provide support and maintenance services for your software that include performance optimization and compliance."
  },
  {
    id: 14,
    text: (<>
      <h5>Software applications of various complexity</h5>
      <p>There are many different types of software applications out there, each with its own set of complexities and challenges. Large, complex mobile applications may require teams or more people to develop and maintain them, while business-critical solutions need to meet stringent performance and availability standards.<br />
        Highly secure systems may have additional layers of complexity, and data-intensive applications can be challenging to scale. MVPs (minimum viable products) and libraries present their own unique set of challenges as well.</p>
    </>)
  },
  {
    id: 15,
    text: (<>
      <h5>Go for Cost-Effective and Fast .NET Development</h5>
      <p>Origin Softwares professionals are experts in .NET development and can help you deliver a high-quality product quickly and efficiently.<br /><br />
        We offer elegant business analysis, intuitive UX and UI design, agile development, and mature project management to ensure your project's success. Plus, our team is available at Origin for support and updates.</p>
    </>)
  },
  {
    id: 16,
    text: (<>
      <h5>For all groups of end users</h5>
      <p>Different groups of end users use different types of software. Commercial software products are designed for businesses to use, while B2B software is developed for use by other businesses.<br />
        B2E software is designed for use by employees within an organization, while B2C software is meant for consumers. Custom enterprise platforms and apps are developed specifically for internal use within a company.</p>
    </>)
  }
]

const DotNet = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>.NET Application Development Company | Origin software</title>
          <link rel="canonical" href="./Dot-Net-Application-development" />
          <meta
            name="description"
            content="Origin software, your .NET Application Development Company which deliver business advantages with high-quality programs"
          />
          <meta name="keywords" content=".net application development" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Technologies"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Java-Development-Company"}>Java</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Node-Js-Development-Services"}>Node.Js</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Hire-Python-Developers"}>Python</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/IOS-App-Development-Services"}>iOS</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Andriod-App-Development-Services"}>Android</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Angular-Development-Services"}>Angular</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Flutter-App-Development-Company"}>Flutter</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Dot-Net-Application-development"}>.Net</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Php-Development-Services"}>PHP</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/React-js-development-services"}>React.Js</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Magento-Development-Services"}>Magento</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/WordPress-Development-Company-in-India"}>WordPress</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">.NET Application Development </h1>
                  <img className="img mb--20" src="../images/DotNet.png" alt=""></img>
                  <p className="mb--20"> Origin Softwares is a .Net application development company with extensive experience in developing web applications using the .NET platform. <span style={{ fontWeight: "bold" }}>With over 9 years of experience and successful projects</span> under its belt, Origin Softwares is a company you can trust to deliver quality software quickly and efficiently.</p>

                  <div className="row paper">
                    <h4>Shortlisting Vendors for a .NET Project?</h4>
                    <div className="col-xl-6 col-lg-5">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <p><ul className="b">
                            <li>Shortlisting vendors for a .NET project can be difficult, but Origin Softwares may be the perfect company for you.</li>
                            <li>With 9 years in .NET application development and Microsoft net Gold Competencies, Origin Softwares has the experience you need for a successful project.</li>
                            <li>In addition, Origin Softwares boasts a full-scale PMO .NET developer platform. When you work with Origin Softwares, you can be confident that you're working with the best in the business.</li>
                          </ul></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left shadow-box mb--30">
                          <img className="thumbnail" src="../images/InfoGraphics/dotnetproject.png" alt=""></img>
                        </div>
                      </div>
                    </div>
                  </div><br />

                  <div className="row">
                    <h4>How We Launch .NET application Development Cooperation?</h4>
                    {allData.slice(0, 4).map(item => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4 className="mb--20">Scope of Our .NET Services</h4>
                    {allData.slice(4, 13).map(item => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <img className="thumbnail" src="../images/InfoGraphics/dotnet.png" alt="Thumbnail" />

                  <div className="row mb--20">
                    {allData.slice(13, 16).map(item => (
                      <div className="Box col-xl-7 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <h4>Skills that Drive Our .NET application Development </h4>
                  <div className="row">
                    <div><Technology /></div>
                  </div>

                  <h4>Architecture Patterns We Design</h4>
                  {/* Add Designs here */}
                  <h4>Our .NET Software Architecture Sample</h4>
                  {/* <p>Add the architecture infographic here</p> */}

                  <h4 className="mb--20">Want to Rearchitecture Your .NET Software?</h4>
                  <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link>
                  <h4>Our Selected .NET Projects</h4>
                  <Link to={process.env.PUBLIC_URL + "/Portfolio"} className="axil-btn btn-fill-primary btn-small">Portfolio</Link>

                  <div className="row">
                    <h4>We Know Your Industry</h4>
                    <div><Industries /></div>
                  </div>

                  <Accordion>
                    <h4 className="color">.Net FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Why choose .NET?"
                      body={<>
                        <p><ul>
                          ► .NET core offers many benefits for developers, including free distribution, cross-platform development, and modular net apps that are easy to deploy and maintain. <br />
                          ► Additionally, asp.net core supports object-oriented programming language, which results in cleaner code with more focused units<br />
                        </ul></p>
                      </>}
                    />
                    <AccordionItem
                      eventKey="2"
                      header="Why use .NET?"
                      body={<>
                        <p><ul>
                          ► At Origin Softwares, we specialize in designing back-end architectures using microservices, multi-tenancy, headless (decoupled) architecture, serverless computing, domain-driven design (DDD), and Command Query Responsibility Segregation (CQRS).<br />
                          ► For front-end development, we specialize in Progressive Web Apps (PWA), Single Page Applications (SPA), Model View Controller (MVC), Model View ViewModel (MVVM), and reactive programming. We also have experience with micro-frontends.
                        </ul></p>
                      </>}
                    />
                    <AccordionItem
                      eventKey="3"
                      header="How We Ensure Code Quality?"
                      body={<>
                        <p><ul>
                          ► Coding best practices are important for any software development project. <br />
                          ► Following coding guidelines helps to create consistent, reliable, and secure code. <br />
                          ► Using descriptive names for variables and adding comments to code can help make the code more readable and maintainable. <br />
                          ► Code should be split into short units that are focused on a single task to improve readability and maintainability. <br />
                          ► Version control tools help to track changes to the code and ensure that the code can be easily rolled back to a previous version if necessary. <br />
                          ► Unit tests verify that the code behaves as expected in response to different inputs.<br />
                          ► Unit tests are created as part of the functional requirements specification and should be run after each significant change to the code to ensure proper performance.<br />
                          ► Code review practices can be divided into five groups: ad hoc review, peer review, code walkthrough, code inspection, and code quality metrics. <br />
                          ► Ad hoc review is the least formalized and structured of the five groups, usually involving two programmers who check each other's code informally.<br />
                          ► Peer review is a more formalized process in which two or more programmers review each other's code to find errors and potential improvements.<br />
                          ► Code walkthrough is a process in which the programmer who wrote the code explains it to one or more other programmers who take notes and ask questions.<br />
                          ► Code inspection is a process in which a team of two or more people analyzes the code to find errors and potential improvements. This process is often done using tools that automate some or all of the analysis.<br />
                          ► Code quality metrics are tools that measure various aspects of the code, such as its maintainability index (MI) or cyclomatic complexity (CC).<br />
                          ► Our .NET development team is ready to help. We have a highly productive and flexible team of developers who can provide the skills and expertise you need to get the job done right. <br />
                          ► Origin Softwares is dedicated to providing quality services and we are confident that we can exceed your expectations. <br />
                          ► Contact us today to learn more about our .NET development services.<br />
                          <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small" >Contact Us</Link>
                        </ul></p>
                      </>}
                    />
                    <AccordionItem
                      eventKey="4"
                      header="Why Choose Our .NET Development Services?"
                      body={<>
                        <p><ul>
                          ► Our .NET development services are the perfect choice for businesses that need expert project management and fast, efficient development. Our team of experienced.<br />
                          ► NET developers are highly productive and proficient in the latest .NET technologies, which means we can deliver your project net solutions in a fraction of the time of other development companies.<br />
                          ► Plus, our Agile, DevOps, and component reuse approach can save you up to 50% in project costs.<br />
                        </ul></p>
                      </>}
                    />
                    <AccordionItem
                      eventKey="5"
                      header="What our Customers Say?"
                      body={<>
                        <ul>
                          ►  “ Really appreciate Origin Software Team. I’ve worked with quite a few SEO firms and so far, they’ve done the best. Thanks for supporting my business as I needed.”   -Nouman Dawer<br />
                          ►  “ The team has put in extraordinary efforts to finish the project and helped our business grow fast. Very focused and dedicated collaboration. Thanks.” -Mohammed Jawad<br />
                          ►  “ One of the finest organisation have been associated with in the recent times energetic with full young enthusiastic team keep it up guys.” -Owais Shamsuddin<br />
                          ►  “One of the finest organization to collaborate with very awesome back hand team to support go a head team origin good luck.” -Farha Ansari
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="6"
                      header="Need .NET Developers for a Dedicated/Augmented Team?"
                      body={<>
                        <p><ul>
                          ► We have extensive experience developing software with high performance and good user experience for a variety of industries. Click on the icons below to learn more about our .NET experience in your industry.<br /><br />
                          <h6>Choose Your Cooperation Model</h6>
                          ► If you'relooking for a team of skilled .NET developers to help with your project, look no further than origin software. <br />
                          ► Our developers are experts in the .NET framework and can help you get your project done on time and within budget. We offer two pricing models: hourly rate or time and materials with a cap.<br /><br />
                          <h6>Choose the option that best fits your needs and budget.</h6>
                          ► Origin Softwares team of BAs, UX/UI designers, developers, PMs, and QAs can provide full-cycle .NET development outsourcing. <br />
                          ► Depending on the project requirements, we can offer a fixed price or time and materials agreement. Our pricing is very competitive, and we can work within your budget to get your project done quickly and efficiently.<br />
                        </ul></p>
                      </>}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default DotNet;
// 586