import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerOne from "../elements/breadcrumb/BcrumbBannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import CounterUpTwo from "../component/counterup/CounterUpTwo";
import TeamOne from "../component/team/TeamOne";
import VideoTwo from "../component/video/VideoTwo";
import { Helmet } from "react-helmet-async";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const Team = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>Team | Origin software</title>
          <link rel="canonical" href="./Team" />
          <meta
            name="description"
            content="Meet the experienced management team behind our software development company. our leaders guide our company to success."
          />
          <meta name="keywords" content="Team" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher/>
          <BcrumbBannerOne
            title="Meet talent &amp; experience"
            paragraph="Grow your business online by awesomely designed mobile apps that fits all types."
            styleClass="thumbnail-2"
            mainThumb="/images/banner/banner-thumb-2.pg"
          />
          <CounterUpTwo />
          <TeamOne />
          <VideoTwo />
          <CtaLayoutOne />
          <FooterOne parentClass="" />
        </main>
      </div>
    </>
  );
};

export default Team;
