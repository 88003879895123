import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import GoogleTagManager from './GTM';

const RC = [
    {
        id: "1",
        title: "Scope:",
        description: "This refund policy applies to all services provided by Origin Softwares, including but not limited to website development, design, and related services."
    },
    {
        id: "2",
        title: "Refund Eligibility:",
        description: (<>Refunds will be considered only in cases where Origin Softwares fails to deliver the agreed-upon services as outlined in the project scope.<br />
            No refunds will be provided once the project has been completed and the final deliverables have been approved by the client.
        </>)
    },
    {
        id: "3",
        title: "Request Process:",
        description: (<>Clients must submit a refund request in writing to <a href='mailto:info@originsoftwares.com'>info@originsoftwares.com</a>.
            The request must detail the specific reasons for seeking a refund and include any supporting documentation.
        </>)
    },
    {
        id: "4",
        title: "Review Process:",
        description: (<>Origin Softwares will review the refund request within 90 days of receipt.<br />
            If the request is deemed valid, Origin Softwares will initiate the refund process.
        </>)
    },
    {
        id: "5",
        title: "Refund Method:",
        description: (<>Refunds will be issued using the same payment method used for the original transaction.<br />
            Processing times for refunds may vary depending on the payment provider.
        </>)
    },
    {
        id: "6",
        title: "Exceptions:",
        description: "No refunds will be provided for additional features or services requested by the client outside the original project scope."
    },
    {
        id: "7",
        title: "Cancellation by Client:",
        description: (<> Clients may request to cancel a project at any time.<br/>
            If the cancellation request is made before the commencement of the project, a full refund will be provided.<br/>
            If the cancellation request is made after the project has started, Origin Softwares reserves the right to retain a portion of the project fee to cover work already completed.
        </>)
    },
    {
        id: "8",
        title: "Cancellation by Origin Softwares:",
        description: (<>Origin Softwares reserves the right to cancel a project at any time due to unforeseen circumstances, technical issues, or other valid reasons.
            In such cases, clients will be provided with a prorated refund for any unused services.
        </>)
    },
    {
        id: "9",
        title: "Communication:",
        description: (<>All cancellation requests must be submitted in writing to <a href='mailto:info@originsoftwares.com'>info@originsoftwares.com</a>.</>)
    },
    {
        id: "10",
        title: "Project Materials:",
        description: "Upon cancellation, any project materials, designs, or other assets created by Origin Softwares will remain the property of Origin Softwares until full payment is received."
    }
]

const Refundandcancellation = () => {
    <GoogleTagManager gtmId="GTM-WV4R3LC" />
    return (
        <>
            <SEO title="Privacy Policy" />
            <main className="main-wrapper">
                <HeaderOne />
                <ColorSwitcher />
                <BreadCrumbOne
                    title="Refund and Cancellation"
                    page="Refund and Cancellation"
                />
                <div className="section bg-color-light">
                    <div className="container pad">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <h3>Refund Policy:</h3>
                                {RC.slice(0, 6).map(item => (
                                    <div>
                                        <h5 className='mb--20'>{item.title}</h5>
                                        <p>{item.description}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <h3>Cancellation Policy:</h3>
                                {RC.slice(6,10).map(item =>(
                                    <div>
                                        <h5 className='mb--20'>{item.title}</h5>
                                        <p>{item.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Refundandcancellation;