import React from 'react';

const ColorSwitcher = () => {

    return (
        <div className="my_switcher d-none d-lg-block">
            <a href='/GetAQuote' style={{ color: "white", fontWeight : "bold" }} >Get A Quote</a>
        </div>
    )
}

export default ColorSwitcher;