import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className="color1">{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  )
}

const jsonData = {
  "services": [
    { title: "Automate Digital Marketing", link: "/Digital-Marketing-Agency-in-Hyderabad" },
    { title: "ECommerce Website Design", link: "/Ecommerce-Website-Development-Services" }
  ]
}

const allData = [
  {
    id: 1,
    text: (<>
      <h5>Ecommerce development</h5><br></br>
      <p>Being able to develop eCommerce websites that are custom tailored allows us the freedom to work with businesses of all types and sizes rather than being limited to only those that fall into a specific category. It allows us to create solutions better suited for each client rather than a one-size-fits-all approach.</p>
    </>)
  },
  {
    id: 2,
    text: (<>
      <h5>Microservices-based eCommerce</h5>
      <p>Microservices are often seen as ideal because of how much they can customize them, scale both vertically and horizontally, and isolate faults. We have talented solution architects with years of experience planning and implementing logical components (microservices) with dependencies that preserve component isolation in the unfortunate event that one should fail.</p>
    </>)
  },
  {
    id: 3,
    text: (<>
      <h5>Online Marketplaces</h5><br></br>
      <p>We build online business marketplaces for products and services that vary in how sellers are engaged, how orders are processed, and how revenue is managed.Throughout the design and development process, we keep the needs of three key groups in mind: marketplace owners and administrators, sellers, and end consumers. It allows us to create a marketplace tailored to your specific vision while giving sellers certain independence.</p>
    </>)
  },
  {
    id: 4,
    text: (<>
      <h5>B2C eCommerce</h5>
      <p>With B2C eCommerce development, there's more to it than having a great UI. We take things further by analyzing the business context and considering factors like traffic patterns, consumer doubts about your product, and customer acquisition channels. It helps us create B2C web stores that are scalable and have high conversion potential.</p>
    </>)
  },
  {
    id: 5,
    text: (<>
      <h5>B2B eCommerce</h5>
      <p>We work with B2B businesses because we understand their buyers are highly rational. We build user journeys that help facilitate and prompt their decision-making process because we know that in B2B eCommerce solutions, it's important to personalize the buyer's experience.We also set up negotiation workflows between companies and buyers and provide diversified access to different team roles so that everyone has what they need to make the best decisions possible.</p>
    </>)
  },
  {
    id: 6,
    text: (<>
      <h5>Headless Commerce</h5>
      <p>We help create unique template-free designs for different interfaces experienced in decoupled eCommerce architecting. This way, we are free to create any page layout and widgets and connect them via APIs to the back-end functions. With a headless approach, we can create a more fluid experience for the user that is not bound by the restraints of a traditional template design.</p>
    </>)
  },
  {
    id: 7,
    text: (<>
      <h5>PWA</h5>
      <p>Origin software believes that Progressive Web Apps (PWA) are the way to develop one app for both web and mobile audiences. The major advantage of PWAs is that they are responsive, meaning that they adjust their styling and layout depending on the size of the viewport. Additionally, origin software has a ready-made PWA Studio with all the tooling to deliver PWAs quickly and efficiently.</p>
    </>)
  },
  {
    id: 8,
    text: (<>
      <h5>Online Multi-store</h5>
      <p>At origin software, we focus on helping eCommerce businesses reach new heights by expanding to new geographies and market segments. Our multi-store solutions are well-organized and easy to administrate, with support for multiple languages and catalogs. This way, you can focus on running your business while we care for the details for a better user experience.</p>
    </>)
  },
  {
    id: 9,
    text: (<>
      <h5>Web Portals</h5>
      <p>Web portals come in all shapes and sizes, each customizable to fit the unique needs of any retail business. Whether a vendor needs help managing their customers, building their brand, or sharing their catalog, a well-defined portal goal can help them implement the right functional components and set up an efficient workflow.</p>
    </>)
  },
  {
    id: 10,
    text: (<>
      <h6>Platform-based eCommerce Development</h6>
      <p>Adobe Commerce (Magento) is one of the best eCommerce platforms because it's affordable without sacrificing quality. Our team specializes in building Adobe Commerce solutions from the ground up and maintaining and updating existing ones.</p>
    </>)
  },
  {
    id: 11,
    text: (<>
      <h6>Custom eCommerce Development </h6>
      <p>We understand that to achieve more functional flexibility, you might need to consider shifting from a monolithic to a decoupled architecture. We are experts in innovative technologies like voice recognition and 3D modeling, which can help you make your brand stand out from the competition.</p>
    </>)
  },
  {
    id: 12,
    text: (<>
      <h6>Ecommerce Migration </h6>
      <p>We help businesses who have outgrown their current eCommerce solution and need to migrate to a more flexible and scalable platform. Our team will guide you through the data migration process so that you can continue running your business without interruptions.</p>
    </>)
  },
  {
    id: 13,
    text: (<>
      <h6>Ecommerce Website Design </h6>
      <p>At our eCommerce web design company, we include UX and UI aspects in all our designs. We always put the needs of our target audience first and do extensive research on our competition. Plus, we only use the best branding and usability testing techniques. When you choose us, you can be confident that you're getting the best possible service.</p>
    </>)
  },
  {
    id: 14,
    text: (<>
      <h6>Ecommerce Integrations</h6>
      <p>At our company, we're always working to strengthen our expertise in eCommerce store development, and one way we do that is by offering API-based integration services. It allows us to connect separate systems and enable automated data exchange, which makes things run more smoothly and efficiently.</p>
    </>)
  },
  {
    id: 15,
    text: (<>
      <h6>Ecommerce Audit</h6>
      <p>At eCommerce Website Audit, we pride ourselves on quickly and easily detecting code defects, security vulnerabilities, performance, and UX issues on any website or infrastructure.We know that it's tough to keep track of everything that needs to be done to keep your website or online store running smoothly.so we make it our priority to come up with solutions to the problems we detect and then work with you to prioritize and implement those solutions.</p>
    </>)
  }
]

const EcommerceWebsiteDesign = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>
            Ecommerce Website Development Services | Origin software
          </title>
          <link rel="canonical" href="./E-Commerce-Services" />
          <meta
            name="description"
            content="Custom Website Design Top ecommerce website development services in India at affordable cost .Also offers custom design ecommerce solutions for you."
          />
          <meta
            name="keywords"
            content="ecommerce website development services"
          />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="ECommerce Services"
                        body={<>
                          <ul className="a"><li><Link to={process.env.PUBLIC_URL + "/Ecommerce-Website-Development-Services"}>ECommerce Website Design</Link></li></ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Ecommerce Website Design Services </h1>
                  <img className="img mb--20" src="../images/Slider/Ecommerce Website Design Services.png" alt="Ecommerce-Website-Development-Services" />
                  <p className="mb--20">Origin Softwares is a <span style={{ fontWeight: "bold" }}> leading eCommerce website development services company in Hyderabad, India.</span> We as a team
                    understand that eCommerce website design and development is about more than just slapping a few photos and a buy button on a page. It's about creating a premium eCommerce experience to win over even the most demanding modern consumers.</p>
                  <p>When you partner with us, you'll be working with eCommerce developers who are not only savvy in high-performance eCommerce architectures but also know how to create conversion-driven UIs.</p>
                  <p>Origin Softwares has been in the game since 9 Years. it gave us a major advantage when we started expanding our eCommerce development company - our clients could hire a team with extensive technical intelligence and creativity.</p>
                  <div className="row">
                    <h4 className="mb--20">Our Areas of Expertise</h4>
                    {allData.slice(0, 9).map(item => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className='mb--20'>Our Industries</h3>
                    <div><Industries /></div>
                  </div>

                  <div className="row">
                    <h4 className="mb--20">Key Services We Render</h4>
                    {allData.slice(9, 15).map(item => (
                      <div className="Box col-xl-6 col-lg-6">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <h4 className="mb--20">Our Technology</h4>
                  <div className="mb--20">
                    <Technology />
                  </div>

                  <h4 className="mb--20">Our Project Flow </h4>
                  <img className="thumbnail" src="../images/InfoGraphics/ourprojectflow.png" alt="Thumbnail" />

                  <h4 className="mb--20">Contact Our Team </h4>
                  <p className="mb--20"> At some point, every business faces challenges or growth plans. Maybe you're not generating enough sales or want to redesign your website. Maybe you need help with marketing or eCommerce development. Whatever it is, our team can help you find the best solution.<br></br>
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small" >Contact Us</Link></p>

                  <div className="row">
                  <h4>Other services </h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className='b'><li><a href={process.env.PUBLIC_URL + services.link}>{services.title}</a></li></ul>
                      </div>
                    ))}
                  </div>

                  <Accordion>
                    <h4 className="color">ECommerce Website Design FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="What is your eCommerce website development process?"
                      body={<>
                        <p>► At the start of every project, our developers sit down and learn about what your project requires and your business needs. <br />
                          ► We decide on the tech stack, map out the project, and establish its scope with that information. We also create use cases and user stories.<br />
                          ► Afterward, we move on to software design and custom code creation. We test the software thoroughly as we go along. <br />
                          ► Once the software is ready, we do end-to-end quality assurance and implement it into your eCommerce ecosystem. <br />
                          ► Last, we deliver user training, create user guides, and can provide long-term support and maintenance if you need us to as customer satisfaction is our goal.<br /></p>
                      </>}
                    />
                    <AccordionItem
                      eventKey="2"
                      header="How long does custom eCommerce software development take?"
                      body={<>
                        <p> ► How long an eCommerce project takes can vary depending on the ultimate solution's complexity.<br />
                          ► If you use an existing eCommerce platform and don't integrate too many other features, you can launch much faster than if you develop everything from scratch.<br />
                          ► In most cases, developing everything from scratch takes months. We already have several tools for online retail.<br /></p>
                      </>}
                    />
                    <AccordionItem
                      eventKey="3"
                      header="Can you integrate our new eCommerce solution with them?"
                      body={<>
                        <p>► Our eCommerce web developers can help you by integrating your newly built eCommerce store with back-end tools and customer-facing software. <br />
                          ► We will analyze your infrastructure and requirements to select the best integration method and tech stack.<br />
                          ► Our team will also ensure that the integration is easy to scale, adaptable, and secure. This way, no customer or business data will be lost or intercepted.<br /></p>
                      </>}
                    />
                    <AccordionItem
                      eventKey="4"
                      header="Can you make our web eCommerce solution mobile-friendly?"
                      body={<>
                        <p>► Our eCommerce website development services team can help you build a properly optimized website for mobile use so that you don't have to build a separate eCommerce mobile application.<br />
                          ► However, keep in mind that mobile-optimized websites might not perform as quickly as native mobile apps although it improves customer experience.<br /></p>
                      </>}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default EcommerceWebsiteDesign;