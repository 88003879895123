import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const Brochure = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>Brochure Design India | Origin software</title>
          <link rel="canonical" href="./Brochure-Design-India" />
          <meta
            name="description"
            content="India's leading brochure designing company. We offer brochure design,flyer design, leaflet design and logo design services in India."
          />
          <meta name="keywords" content="BROCHURE DESIGN INDIA" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Coporate Branding"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Logo-Design-Company-in-India"}>Logo Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Presentation-Design-India"}>Presentation</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Brochure-Design-India"}>Brochure</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Promotional-Products"}>Promotional Material</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Brochure Design</h1>
                  <img className="img mb--20" src="../images/Slider/Brochure.png" alt="Brochure-Design-India" />
                  <p>The Importance of Brochure Design<br /><br />
                    In today's digital world, print media still plays an important role in marketing and advertising. Traditional print media provides consumers with something tangible to hold onto and view.<br /><br />
                    At Ideal branding, we understand the importance of brochure and poster design. We have a team of experienced designers who are dedicated to creating posters and brochures that are both creative and eye-catching, while still delivering your message and maintaining your brand image.<br />
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link> </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default Brochure;