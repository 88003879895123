import React from 'react';
//import { Link } from 'react-router-dom';

const Data = [
    {
        name: "Java",
        image: "/images/technology/java.png",
        link: "/Java-Development-Company"
    },
    {
        name: "Node.js",
        image: "/images/technology/node.png",
        link: "/Node-Js-Development-Services"
    },
    {
        name: "Python",
        image: "/images/technology/python.png",
        link: "/Hire-Python-Developers"
    },
    {
        name: "IOS",
        image: "/images/technology/iOS.png",
        link: "/IOS-App-Development-Services"
    },
    {
        name: "Andriod",
        image: "/images/technology/android.png",
        link: "/Andriod-App-Development-Services"
    },
    {
        name: "Angular",
        image: "/images/technology/angular.png",
        link: "/Angular-Development-Services"
    },
    {
        name: "Flutter",
        image: "/images/technology/flutter.png",
        link: "/Flutter-App-Development-Company"
    },
    {
        name: ".Net",
        image: "/images/technology/dotnet.png",
        link: "/Dot-Net-Application-development"
    },
    {
        name: "PHP",
        image: "/images/technology/php.png",
        link: "/Php-Development-Services"
    },
    {
        name: "React",
        image: "/images/technology/react.png",
        link: "/React-js-development-services"
    },
    {
        name: "Magento",
        image: "/images/technology/magento.png",
        link: "/Magento-Development-Services"
    },
    {
        name: "WordPress",
        image: "/images/technology/wordpress.png",
        link: "/WordPress-Development-Company-in-India"
    }
]

const Technology = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-xl-2 col-lg-6 cool-link" key={index}>
                    <div className="btm-title-img">
                        <a href={process.env.PUBLIC_URL + data.link}>
                            <img src={process.env.PUBLIC_URL + data.image} alt={data.name} />
                        </a>
                    </div>
                </div>
            ))}
        </>
    )
}

export default Technology
