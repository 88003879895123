import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className="color1">{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  )
}

const jsonData = {
  "services": [
    { title: "UI Design", link: "/UI-Design-Services" },
    { title: "UX Design", link: "/UX-Design-Services" },
    { title: "Responsive Web Design", link: "/Responsive-Web-Design-Services" },
    { title: "Responsive Web App Design", link: "/Responsive-Web-Application" },
    { title: "Website Redesign", link: "/Website-Redesign-Services" }
  ]
}

const allData = [
  {
    id: 1,
    text: "68% of visitors will leave a company website if they don't like the UI or UX design."
  },
  {
    id: 2,
    text: "46% of visitors make business decisions based on a company's website visuals."
  },
  {
    id: 3,
    text: "$1.73 Billion in annual revenue is lost by slow-loading eCommerce websites."
  }
]

const WebsiteRedesign = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>Website Redesign Services | Origin software</title>
          <link rel="canonical" href="./Website-Redesign-Services" />
          <meta
            name="description"
            content="Origin software presents proven and future-proof solutions for your website, companies and business."
          />
          <meta name="keywords" content="website redesign services" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Website Design"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/UI-Design-Services"}>UI Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/UX-Design-Services"}> UX Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Responsive-Web-Design-Services"}> Responsive Web Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Responsive-Web-Application"}> Responsive Web App Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Website-Redesign-Services"}> Website Redesign</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Website Redesign</h1>
                  <img className="img mb--20" src="../images/Slider/Website Redesign.png" alt="Website-Redesign-Services" />
                  <p>If you're not happy with how your website looks or performs, website redesign services can help give it a makeover. UX and UI revamps can go a long way in terms of transforming a website's appearance and feel. And at Origin Softwares, we don't just stop there – our website redesign services also cover things like loading speed optimization and content migration to ensure that visitors have a good experience.</p>

                  <h4>Why You Might Consider Getting Website Redesign Services?</h4>
                  <p>Here are some statistics that might convince you to give our website redesign services a try:</p>

                  <div className="row">
                    {allData.slice(0, 3).map(item => (
                      <div key={item} className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3>We Deal With this Technologies</h3>
                    <div><Technology /></div>
                  </div>

                  <div className="row">
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className='b'><li><a href={process.env.PUBLIC_URL + services.link}>{services.title}</a></li></ul>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3>Our Industries</h3>
                    <div><Industries /></div>
                  </div>

                  <Accordion>
                    <h4 className="color">Website Redesign FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Why Origin Softwares?"
                      body={<>
                        <ul>
                          <p>► We've got 9 years in IT under our belt, and we have a team of dedicated and experienced UI and UX designers.<br />
                            ► Plus, we're experts in performing UI and UX research and testing, and we always design with mobile responsiveness in mind.</p>
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="2"
                      header="How You Benefit from Website Redesign with Origin Softwares?"
                      body={<>
                        <ul>
                          ► At Origin Softwares, we guarantee website enhancements by starting with an in-depth UI and UX audit.<br />
                          ► This lets us identify your website's weak points, which we then address with a custom plan that consists of redesign goals and respective business outcomes. <br />
                          ► For example, we can improve responsive mobile design to increase mobile traffic, fix page load and performance issues to reduce the visitor bounce rate or unify several websites into one CMS to reduce support and administration costs.<br />
                          <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link>
                        </ul>
                      </>}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default WebsiteRedesign;