import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import Industries from "../component/Industries/Industries.js";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  "services": [
    { title: "Android Apps", link: "/Andriod" },
    { title: "iOS Apps", link: "/IOS" },
    { title: "React Native Mobile App", link: "/React-Native-App-Development-Company" },
    { title: "Xamarin Apps", link: "/Xamarin-App-Development" },
    { title: "Hybrid Apps", link: "/Hybrid-App-Development" },
    { title: "Mobile App Design", link: "/Mobile-App-Design-Services" },
    { title: "Mobile Ecommerce Apps", link: "/Mobile-Ecommerce-App-Development" }
  ]
}

const allData = [
  {
    id: 1,
    text: "Platform apps like React Native, Xamarin, and Flutter have become extremely popular in recent years, especially for enterprise apps and monetized products. However, there are a few different ways to develop a mobile app, each with its own advantages and disadvantages."
  },
  {
    id: 2,
    text: "If you're not sure which approach is right for your project, origin software can help. We offer a free, no-obligation consultation that will give you an overview of your options, an estimate of the project cost, and more. Simply fill out our short questionnaire and we'll get started."
  },
  {
    id: 3,
    text: "Origin Softwares provides mobile app development services that guide our clients from beginning to end. We start with consulting, in order to help finalize the app concept, plan activities, and optimize costs."
  },
  {
    id: 4,
    text: "Then, our designers create a sleek and intuitive interface that is easy to use and engage with. Conversion is key in any design, and our team makes sure that your app will have high conversion rates."
  },
  {
    id: 5,
    text: "Finally, we develop and implement the app, according to your specific needs and wants."
  },
  {
    id: 6,
    text: "At Origin Softwares, we specialize in web development and can complement your mobile app with a web app, web portal, online store, or SaaS."
  },
  {
    id: 7,
    text: "Our team of experts is well-versed in a wide range of APIs, and we guarantee seamless integration with back ends and any third-party software."
  },
  {
    id: 8,
    text: "In addition, our ISTQB-certified test engineers can perform functional, performance, security, UX, and accessibility testing."
  },
  {
    id: 9,
    text: "Once your app is launched, origin software offers post-launch L0-L3 support, compliance, security, and performance management &amp; app evolution."
  },
  {
    id: 10,
    text: "If you're looking to modernize your mobile app, look no further than Origin Softwares!"
  }
]

const MobileApps = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>Mobile app development services | Origin softwares</title>
          <link rel="canonical" href="./Mobile-App-Development-Services" />
          <meta
            name="description"
            content="we offer mobile app development services that focus on delivering world-class web and mobile applications by leveraging the power of the latest technologies."
          />
          <meta name="keywords" content="Mobile app development services" />
        </Helmet>
        <main className="main wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Mobile Apps"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Andriod"}>Android Apps</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/IOS"}>iOS Apps</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/React-Native-App-Development-Company"}>React Native Mobile App</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Xamarin-App-Development"}>Xamarin Apps</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Hybrid-App-Development"}>Hybrid Apps</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Mobile-App-Design-Services"}>Mobile App Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Mobile-Ecommerce-App-Development"}>Mobile Ecommerce Apps</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Mobile App Development </h1>
                  <img className="img mb--20" src="../images/Slider/Mobile App Development.png" alt="Mobile-App-Development-Services" />
                  <p>At Origin Softwares, we develop mobile apps that are designed to either supplement or replace web solutions. With 20+ mobile app development projects under our belt, we have the eience and expertise necessary to deliver stunning UI, secure code, and robust back-ends.</p>

                  <h5 className="mb--20">We specialize in these types of apps:</h5>
                  <img className="thumbnail" src="../images/InfoGraphics/typesofapps.png" alt="Thumbnail" />

                  <div className="row">
                    <h3 className='mb--20'>Our Industries</h3>
                    <div><Industries /></div>
                  </div>

                  <div className="row">
                    {allData.slice(0, 4).map(item => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4>Other services </h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                          <ul className="b"><li><a href={process.env.PUBLIC_URL + services.link}>{services.title}</a></li></ul>
                      </div>
                    ))}
                  </div>

                  <div className="row mb--20">
                    {allData.slice(4, 10).map(item => (
                      <div className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4 className="mb--20">Our Client's</h4>
                    <TestimonialTwo />
                  </div>

                  <div>
                    <h4 className="mb--20">Technologies We Use</h4>
                    <Technology />
                  </div>

                  <Accordion>
                    <h4 className="color">Mobile App FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="How much does it cost to develop a Mobile App?"
                      body={<>
                        <ul>
                          <p>
                            ► The cost of developing a mobile app depends on the app's functional scope as well as the technology of its implementation. <br />
                            ► A native app of medium complexity can cost anywhere from XYZ to XYZ. At Origin Softwares, we use an agile approach and cross-platform tools to minimize these costs.<br />
                            ► Depending on the scope and tech stack, mobile app development can take anywhere from 3 to 10 months.<br />
                            However, at origin software, we prioritize features and focus on the core ones first to reduce time-to-market.<br />
                            ► This allows us to roll out an MVP app in just 2-3 months. We also have a mature system of KPIs (budget required vs. budget remaining, cycle time, team’s proactivity, etc.) that we use in managing our mobile app projects. <br />
                            ► This ensures transparency and cooperation among our team members.<br />
                            ► At Origin Softwares, we understand that each app is unique and therefore will have different monetization needs. We offer additional benefits and services for all types of apps to help you maximize your revenue. <br />
                            ► For apps that are sold in mobile stores, our UX and UI design team can help create a store browsing experience that is enjoyable for users and results in high conversions.<br />
                            ► If your app uses ads or is a freemium app, we can help with ad placement to eliminate user frustration and feature prioritization to make sure the most important features are not hidden behind a paywall. <br />
                            ► For paid apps, we offer monitoring and compliance services with App Store and Google Play policies, as well as user issue resolution, to ensure a high rating. <br />
                            ► Origin Softwares is committed to helping you reinforce your app monetization strategy so you can achieve your desired results.<br />
                            ► There are a lot of different factors to consider when developing a mobile app, so it's important to choose the right service option for your project. <br />
                            ► We offer full mobile app development packages as well as specific services like feasibility studies, user research, tech stack advisory, platform, and device compatibility studies, budget estimation, and app architecture blueprints.<br />
                            ► If you're interested in learning more about our mobile app design and development services, click the button below.<br />
                            ► Our mobile app design and development services cover everything from UX research and UI design to app architecture design, mobile app development, back-end development, QA, integration, and rollout/publication. <br />
                            ► We also offer continuous evolution services to keep your app up-to-date.<br />
                            ► If you're interested in modernizing your mobile app, we offer a comprehensive suite of services including UX/UI audit & redesign, mobile app code review, mobile (+web) app re-engineering, architecture redesign, database redesign, and custom API development.<br />
                            ► To learn more about our mobile app development services, please reach out to our representatives. We'll be happy to share details about our past projects and how we can help you achieve your goals.<br />
                          </p>
                        </ul>
                      </>}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default MobileApps;
