import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  "services": [
    { title: "UI Design", link: "/UI-Design-Services" },
    { title: "UX Design", link: "/UX-Design-Services" },
    { title: "Responsive Web Design", link: "/Responsive-Web-Design-Services" },
    { title: "Responsive Web App Design", link: "/Responsive-Web-Application" },
    { title: "Website Redesign", link: "/Website-Redesign-Services" }
  ]
}

const allData = [
  {
    id: 1,
    text: (<>
      <h5>Website Design</h5>
      <p>We'll create an appealing interface that will keep visitors engaged on your site.</p>
    </>)
  },
  {
    id: 2,
    text: (<>
      <h5>Mobile App Design</h5>
      <p>We'll craft a mobile UI that is striking and easy to use, ensuring that your app gets placed on users' home screens.</p>
    </>)
  },
  {
    id: 3,
    text: (<>
      <h5>Web and Mobile Design</h5>
      <p>We can create a responsive web app for desktop and mobile devices, or a mobile app and web app with matching UI.</p>
    </>)
  },
  {
    id: 4,
    text: (<>
      <h5>SaaS Design</h5>
      <p>We'll work with you to create the software as a service that meets all of your needs.</p>
    </>)
  }
]

const UIDesign = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>UI Design Services (User Interface)| Origin software</title>
          <link rel="canonical" href="./UI-Design-Services" />
          <meta
            name="description"
            content="Get unique, customised and extensive UI Design Services from Origin software. we have been providing 100% reliable UI Designs to our clients."
          />
          <meta name="keywords" content="ui design services" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Website Design"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/UI-Design-Services"}>UI Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/UX-Design-Services"}> UX Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Responsive-Web-Design-Services"}> Responsive Web Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Responsive-Web-Application"}> Responsive Web App Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Website-Redesign-Services"}> Website Redesign</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">UI Design Services (User Interface)</h1>
                  <img className="img mb--20" src="../images/Slider/UI.png" alt="UI-Design-Services" />
                  <p>UI design services help to create digital interfaces that are pleasing to the eye and help to strengthen your brand. Our UI Design solutions are tailor-made for your audience, reflect your customers' values and support your business objectives effectively.</p>

                  <h4 className="mb--20">You want your visitors to stick around, right?</h4>
                  <p>Origin Softwares will help you develop a profound user research strategy, slick UIs that your target audience will love.<br />
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small" >Contact Us</Link></p>

                  <h4 className="mb--20">Our Selected Projects</h4>
                  <Link to={process.env.PUBLIC_URL + "/Portfolio"} className="axil-btn btn-fill-primary btn-small" >Portfolio</Link>

                  <div className="row">
                    <h3 className='mb--20'>Our Software Technologies</h3>
                    <div><Technology /></div>
                  </div>

                  <h4 className="mb--20">Our UI Design Service Options</h4>
                  <p>We provide a variety of design services to choose from, whether you need one-time support or continuous design assistance. Our team can help your website evolve over time, and we offer services for every type of device.</p>
                  <div className="row">
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className='b'><li><a href={process.env.PUBLIC_URL + services.link}>{services.title}</a></li></ul>
                      </div>
                    ))}
                  </div>

                  <div className="row mb--20">
                    <h5>Some of our design offerings include:</h5>
                    {allData.slice(0, 4).map(item => (
                      <div key={item.id} className="Box col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className='mb--20'>Our Industries</h3>
                    <div><Industries /></div>
                  </div>

                  <Accordion>
                    <h4 className="color">UI Design FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Why Origin Softwares?"
                      body={<>
                        <ul>
                          <p>► We've got 9 years in IT under our belt, and we have a team of dedicated and experienced UI and UX designers.</p>
                          <p>► Plus, we're experts in performing UI and UX research and testing, and we always design with mobile responsiveness in mind.</p>
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="2"
                      header="Why does UI Design Matter?"
                      body={<>
                        <ul>
                          <p>► Design plays a crucial role in the success of any digital product. A well-designed user interface can increase clicks by 10%, while poor design can lead to 80% of customers abandoning a product.</p>
                          <p>► At Origin Softwares, we create tailored UI designs that are competitive and meet the needs of your customers. Our team stays up-to-date on current design trends to give you an edge over your competition.</p>
                          <p>► We design user interfaces that are tailored to meet your specific marketing and customer service objectives. This includes things like increasing your conversion rate or fostering more user engagement and retention.</p>
                          <p>► To ensure that we're always on the same page, we'll share early drafts and samples of your UI design project before any contract is signed. That way, there's no confusion about the project vision.</p>
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="3"
                      header="Why You Should Invest in UI Design services?"
                      body={<>
                        <ul>
                          <p>► User interface and user experience design are important factors to consider when developing a new product or website. Poor design can cause up to 80% of potential users to leave, so it's important to invest in quality UI design from the beginning.</p>
                          <p>► This will help you stay within budget and get the most satisfied customers.</p>
                          <p>► You can avoid costly mistakes by investing in quality UI design now.</p>
                          <p>► Origin Softwares UI design services team will make sure your user interface is effective and user-friendly, helping you to convert more customers.</p>
                        </ul>
                      </>}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default UIDesign;