import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import Industries from "../component/Industries/Industries";
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  "services": [
    { title: "Software Consulting", link: "/Software-Consulting" },
    { title: "Custom Software Development", link: "/Custom-Software-Development-Company-in-India" },
    { title: "Software Development Outsourcing", link: "/Software-Development-Outsourcing" },
    { title: "Software Product Development", link: "/Software-Product-Development" },
    { title: "Software Development Automation", link: "/Software-Development-Automation" },
    { title: "Software Maintenance and support", link: "/Software-Maintenance-and-Support" }
  ]
};

const allData = [
  {
    id: 1,
    text: (
      <>
        <h5 className="title">Low-Code Development </h5>
        <p>Low-code development is a method of building apps using pre-configured platforms, which can drastically reduce the time needed to develop an app.</p>

      </>
    )
  },
  {
    id: 2,
    text: (
      <>
        <h5 className="title">CI/CD Implementation </h5>
        <p>CI/CD implementation refers to creating a process for automating the release of new code versions into production. This process can take 3-5 weeks to set up.</p>

      </>
    )
  },
  {
    id: 3,
    text: (
      <>
        <h5 className="title">Test automation </h5>
        <p>Test automation It might take up to 2 months to test automate.</p>
      </>
    )
  },
  {
    id: 4,
    text: "Microsoft Power Apps is a service that allows users to build apps for their business. It is a service that allows users to build custom apps with no coding."
  },
  {
    id: 5,
    text: " The service will enable users to build apps for their businesses. It is a cloud-based service that allows users to build apps that are easy to create, manage and update."
  },
  {
    id: 6,
    text: "Test automation is when you use a computer to test for you. Instead of having a person do the testing, you have a computer do it."
  },
  {
    id: 7,
    text: "Test automation is a process of testing software tools. Automated testing is used to make sure the software works correctly. Computers do automated testing"
  },
  {
    id: 8,
    text: "Test automation is a lot of work. You need to be able to write the code that the computer can understand. You also need to be able to tell the computer what to do."
  },
  {
    id: 9,
    text: "You use test automation to make sure that your software is working right. You can also use test automation to ensure your software works on different operating systems, browsers, and mobile devices."
  },
  {
    id: 10,
    text: "It makes the testing go faster. It also makes it so you can test a lot of different things."
  },
  {
    id: 11,
    text: "Test automation is good because it saves time. It is also good because it can make sure."
  },
  {
    id: 12,
    text: "The computers run without manual tests to ensure the software is working correctly."
  },
  {
    id: 13,
    text: (
      <>
        <h6>Pros:</h6>
        <p>A vendor assumes full responsibility for the team assembly and management. Established frameworks for test automation, CI/CD pipeline introduction, and management.</p>
      </>
    )
  },
  {
    id: 14,
    text: (
      <>
        <h6>Cons:</h6>
        <p>I don't have control over the specific process setup. All organizational efforts are on your side.</p>
      </>
    )
  },
  {
    id: 15,
    text: (
      <>
        <h6>Pros:</h6>
        <p>Have Complete control over the automation process setup. Established frameworks for test automation, CI/CD pipeline introduction, and management.</p>
      </>
    )
  },
  {
    id: 16,
    text: (
      <>
        <h6>Cons:</h6>
        <p>Specific resources may be required, which are not viable to hire for a one. Established frameworks for test automation, CI/CD pipeline introduction, and management.</p>
      </>
    )
  },
  {
    id: 17,
    text: "Origin Softwares is a DevOps tool that allows you to automate many different aspects of software development."
  },
  {
    id: 18,
    text: "Origin Softwares provides an end-to-end DevOps journey for automating your software development."
  },
  {
    id: 19,
    text: "Origin Softwares is the most feasible automation tool for automating software development."
  },
  {
    id: 20,
    text: "Origin Softwares is a DevOps tool that allows you to automate many different aspects of software development."
  },
  {
    id: 21,
    text: "Origin software provides an end-to-end DevOps journey for automation."
  },
  {
    id: 22,
    text: "KPI-based automation quality control."
  }
]

const SoftwareDevelopmentAutomation = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>Software Development Automation | Origin software</title>
          <link rel="canonical" href="./Software-Development-Automation" />
          <meta
            name="description"
            content="Origin software is a leading provider of Software Development Automation solutions for Global companies."
          />
          <meta name="keywords" content="Software development automation" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Software Development"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Consulting"}> Software Consulting</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Custom-Software-Development-Company-in-India"}>Custom Software Development</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Development-Outsourcing"}> Software Development Outsourcing</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Product-Development"}> Software Product Development</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Development-Automation"}> Software Development Automation</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Maintenance-and-Support"}> Software Maintenance and support</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Software Development Automation</h1>
                  <img className="img mb--20" src="../images/Software Development Automation.png" alt="Software-Development-Automation" />
                  <p>Software development automation uses various tools to minimize errors, speed up software development, and improve team productivity.</p>

                  <div className="row mb--20">
                    <h6>Origin Softwares services include low-code development, test automation, and CI/CD implementation.</h6>
                    {allData.slice(0, 3).map(item => (
                      <div key={item.id} className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <h4 className="mb--20">Our setup procedure: Software Development Automation</h4>
                  <p>Here we share general insights and roadmaps for automationapplied by origin software in projects</p>

                  <div className="row">
                    {jsonData.services.map((service, index) => (
                      <div key={index} className="col-xl-6 col-lg-2">
                          <ul className='b'><li><a href={process.env.PUBLIC_URL + service.link}>{service.title}</a></li></ul>
                      </div>
                    ))}
                  </div>
                  <div className="row mb--20">
                    <h4>Software Development Automation Services by Origin Softwares</h4>
                    {allData.slice(3, 12).map(item => (
                      <div key={item.id} className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    <div className="paper col-xl-12 col-lg-2">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <h4>Critical Skills in Our Software Development Automation Projects</h4>
                          <ul className="b">
                            <li>They make the apps using the software development platform.</li>
                            <li>They integrate the apps with enterprise data and other apps.</li>
                            <li>They also create the CI/CD pipelines.</li>
                            <li>They review and modify the CI/CD pipelines.</li>
                            <li>They maintain the CI/CD tools and platforms.</li>
                            <li>They develop and support the CI/CD pipeline configurations.</li>
                            <li>A software developer knows how to write software.</li>
                            <li>They write code to solve problems.</li>
                            <li>They can write code to make computers do things.</li>
                            <li>They can write code to make computers tell us information.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 className="mb--20">Sourcing Models for Software Development Automation</h5>
                  <p>Specific resources may be required, which are not viable to hire for a one-time project. All hiring and organizational efforts are on your side.</p>
                  <div className="row mb--20">
                    <h5>Software development automation process set up</h5>
                    {allData.slice(12, 16).map(item => (
                      <div key={item.id} className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  A mix of both models<br />
                  It includes both pros and cons of the in-house project setup model and automation setup model.<br /><br />
                  <div className="row">
                    <h5>There are many benefits to automating software development with Origin Softwares.</h5>
                    {allData.slice(16, 22).map(item => (
                      <div key={item.id} className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div><br />

                  <div className="mb--20">
                    <h4>Our Technologies</h4>
                    <Technology />
                  </div>

                  <div className="mb--20">
                    <h4>Our Industries</h4>
                    <Industries />
                  </div>

                  <div>
                    <h4>Software Development Automation</h4>
                    <img className="thumbnail" src="../images/InfoGraphics/softwareautomation.png" alt="Thumbnail" />
                  </div>

                  <Accordion>
                    <h4 className="color">Software Development Automation FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="How to go with low code?"
                      body={
                        <>
                          <ul>
                            If you want to go with low-code development, technologies require little to no coding to build applications.<br />
                            ► Many different types of low-code development platforms are available, each with advantages and disadvantages.<br />
                            ► The most popular low-code platform is the LCAP (e.g., Microsoft Power Apps, OutSystems, Mendix).<br />
                            ► LCAPs provide visual drag-and-drop modules that simplify and streamline the process of creating new apps.<br />
                            ► However, there are also other types of low-code platforms available, such as business process management suites (e.g., SAP xMII), multi-experience development platforms (e.g., Adobe Experience Manager), and robotic process automation platforms (e.g., UiPath), and citizen.
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="The essential steps of setting up low-code development with LCAP"
                      body={
                        <>
                          <ul>
                            The second step is to choose the low-code application platform that best fits your specific business demands. To do this, you must consider the aim of your low-code development project.<br />
                            Whether you want to prioritize accelerating productivity for your developers or empowering business people to model, automate, and execute the mission <br />
                            The type of low-code application you want to develop will impact the platform's core technologies, the application components you can develop, and whether or not you have access to source code.<br />
                            ➔ When deciding what type of low-code application to develop, consider things like:<br />
                            ➔ What kind of app do you want to develop?
                            <ul><li>A customer-facing app?</li>
                              <li>An internal app?</li>
                              <li>A web app?</li>
                              <li>A mobile app?</li>
                              <li>A portal?</li></ul>
                            ➔ What non-functional requirements does your app have? Things like security, scalability, and performance.<br />
                            ➔ What app components do you want to develop using low code? The front end? Integration? The back end? Database operations? Data visualization?<br />
                            Before starting a low-code development project, it is crucial to create a roadmap.<br />
                            It will ensure that the project stays on track and does not suffer from any unforeseen delays. Additionally, setting up QA, app performance testing, monitoring, and management processes will help to keep the project running smoothly.<br />
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="3"
                      header="How to implement CI/CD?"
                      body={
                        <>
                          <ul>
                            Finally, implementing CI/CD will help automate software's building, testing, and deploying, making updates quicker and easier.<br />
                            <h6>The key steps of setting up CI/CD</h6>
                            ► Creating a baseline for each project is essential to streamline the deployment process. It's also important to ensure all your dependencies are in place before deployment.<br />
                            ► Once production environments are ready, you can use a code repository like GitHub to synchronize the code between your development and production environments.<br />
                            ► Once you've got everything in place, a continuous delivery tool like Jenkins can take over and create a self-sustaining process of compiling, packaging, and releasing new application versions.<br />
                            ► CI/CD is splitting up a process into two steps, a continuous integration step and a continuous delivery step. The steps are split to allow the developer to focus on one step at a time.<br />
                            ► Splitting up your process can accelerate your development and build a better product.<br />
                            ► If you're looking to set up CI/CD, there are some essential parts to putting it together:
                            <ul><li>It would help if you had a place where it could be stored and processed.</li>
                              <li>It could be a server or even a program on your computer.</li>
                              <li> You need to write the code to keep information about a build.</li>
                              <li>You can create a program that will automatically run your body when you're ready for it.</li></ul>
                            ► You may even want to set up a program that automatically pushes your code live whenever the build is ready.
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default SoftwareDevelopmentAutomation;