import React, { useRef, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Row,} from 'reactstrap';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';
import GoogleTagManager from './GTM';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    )
}

function Popup() {
    <GoogleTagManager gtmId="GTM-WV4R3LC" />
    const [inputValue, setInputValue] = useState('');
    
    const handleInputChange = (event) => {
        const numericInput = event.target.value.replace(/[^0-9+()-]/g, ''); // Remove all non-numeric characters
        setInputValue(numericInput);
    };
    
    const [modal, setmodal] = useState(false)

    const form = useRef();

    const [result, showresult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_dwxrdc6', 'template_a1u31xv', form.current, 'wODK5vqYUZuXuBTMp')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        form.current.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={() => setmodal(!modal)}>
                <ModalHeader
                    color='black'
                    toggle={() => setmodal(!modal)}>
                    <img className="center1" src={process.env.PUBLIC_URL + "/images/logo-1.png"} alt="Site Logo" />
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" className="form-control" name="contact-name" required />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" className="form-control" name="contact-email" required />
                            </div>
                            <div className="form-group">
                                <label>Phone</label>
                                <input type="tel" value={inputValue} onChange={handleInputChange} className="form-control" name="contact-phone" required />
                            </div>
                            <div className="form-group">
                                <button type="submit" className="axil-btn btn-fill-primary" name="submit-btn">Submit</button>
                            </div>
                            <div className="form-group">
                                {result ? <Result /> : null}
                            </div>
                        </form>
                    </Row>
                </ModalBody>
            </Modal>
            <button className='axil-btn btn-fill-white' style={{ bordercolor: "#088dd2" }} onClick={() => setmodal(true)}>
                Register Here
            </button>
        </div>
    )
}

export default Popup





//import { Link } from 'react-router-dom';
/* <Col className='form-group'>
                     <label htmlFor='Name'>Name</label>
                     <input type='text' className='form-control' placeholder='Enter Your Name' required/>
                 </Col>
                 <Col className='form-group'>
                     <label htmlFor='Email id'>Email id</label>
                     <input type='text' className='form-control' placeholder='Enter Your Email Address'required/>
                 </Col>
                 <Col className='form-group'>
                     <label htmlFor='Mobile No.'>Mobile No.</label>
                     <input type='text' className='form-control' placeholder='Enter Your Mobile No.'/>
                 </Col>
                 <Link to={process.env.PUBLIC_URL + "/Contact"} className="axil-btn btn-small btn-fill-white">
                     Submit
                 </Link> */