import React from 'react';
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import SEO from '../common/SEO';
import CareerForm from '../component/contact/CareerForm';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import GoogleTagManager from './GTM';

const ApplyNow = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />     
  return (
    <>
    <SEO title='ApplyNow'/>
    <main className='main-wrapper'>
        <HeaderOne/>
        <ColorSwitcher/>
        <div className='section bg-color-light'>
            <BreadCrumbOne title="Apply Now"/>
            <CareerForm/>
        </div>
        <FooterOne/>
    </main>
    </>
  )
}

export default ApplyNow