import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  "services": [
    { title: "SEO", link: "/SEO-Services-in-Hyderabad" },
    { title: "SMM", link: "/Social-Media-Marketing-agency-in-India" },
    { title: "PPC", link: "/PPC-Services-India" }
  ]
};

const jsonData1 = {
  "services": [
    { title: "Local SEO", link: "#" },
    { title: "Technical SEO", link: "#" },
    { title: "On-Page SEO", link: "#" },
    { title: "Off-Page SEO", link: "#" },
    { title: "Content optimization", link: "#" },
    { title: "Amp", link: "#" },
    { title: "Schema/Structured Data", link: "#" },
  ]
}

const allData = [
  {
    id: 1,
    text: "We're considered one of the top SEO agencies in Hyderabad and across India. We're dedicated to helping our clients to achieve their goals."
  },
  {
    id: 2,
    text: "At our SEO services company in Hyderabad, we understand how important it is for your company's website to be near the top of the Google search results."
  },
  {
    id: 3,
    text: "In today's digital world, a properly designed website is not enough - you need a good SEO strategy to beat out your competitors."
  },
  {
    id: 4,
    text: "Every firm, from small businesses to large corporations, has a team of SEO experts working hard to improve its ranking. We are here to do the same for you."
  },
  {
    id: 5,
    text: "We have a 100 percent success rate with our strategies. Our team is experienced and knowledgeable, and we will help you see results as soon as you work with us."
  },
  {
    id: 6,
    text: "We are always here to help new companies create an online presence or improve their existing SEO strategy."
  },
  {
    id: 7,
    text: "Our SEO company in Hyderabad only uses white hat SEO techniques to help our clients improve their website ranking."
  },
  {
    id: 8,
    text: "We believe that while black hat SEO techniques might get you a better ranking in the short term, they are not long-term solutions and may even lead to search engine penalties."
  },
  {
    id: 9,
    text: "Therefore, we only use white hat SEO marketing strategies to help our clients get better website ranking in google or bing organic results."
  },
  {
    id: 10,
    text: (<>
      <h5>Local SEO: </h5>
      <p>It helps you rank in your local region and gain customers for your business. We will help you rank your Google my business listings on top of search engines.</p>
    </>)
  },
  {
    id: 11,
    text: (<>
      <h5>On-page SEO: </h5>
      <p>Search engines are always changing the way they rank websites. To ensure your site gets the most traffic possible, you need to build it for your users. That means creating interesting and easy content an using keywords and interlinking strategies that will help improve your click-through rates.</p>
    </>)
  },
  {
    id: 12,
    text: (<>
      <h5>Off-page SEO: </h5>
      <p>The last step to ensure you are better reached than your competitors is to build high-quality and relevant backlinks that drive heavy traffic.<br />
        Keyword difficulty: More popular keywords are more expensive and will take longer to show up in search results.</p>
    </>)
  },
  {
    id: 13,
    text: (<>
      <h5>Technical SEO: </h5>
      <p>Web page load speed, crawl ability, and URL structure are important for website quality and security.</p>
    </>)
  },
  {
    id: 14,
    text: (<>
      <h5>SEO Process: </h5>
      <p>SEO is important for any business that wants to be successful online. And while many companies claim to help with your SEO, choosing one that will get your results is important.</p>
    </>)
  },
  {
    id: 15,
    text: "Companies grow when they operate using effective SEO techniques. Growing a company isn't just about the product- in today's age, a lot depends on how you market the brand and its services."
  },
  {
    id: 16,
    text: "Origin Softwares is crucial to have a professional SEO strategist in our team who can ensure that your company will be seen by the customers searching for what you offer. To get a clear view of the best SEO services in Hyderabad, let's inspect what they offer."
  },
  {
    id: 17,
    text: "Search engines are a dime a dozen. They are used to accessing the internet on various topics and in many places worldwide. A search engine's main purpose is to make content more visible or less visible depending on the user's needs."
  },
  {
    id: 18,
    text: "But it's not just businesses that benefit from optimization. Customers also use SEO to help them decide better where to spend their money. 90% will research a product or company online before making a purchase."
  },
  {
    id: 19,
    text: "A page that's been optimized by a professional is always going to get more attention than one that hasn't. About three of every four web users will skip pages that don't look like they were put together by someone who knows what they're doing."
  },
  {
    id: 20,
    text: "There's no doubt that SEO works wonders for companies worldwide. 85% of them have seen amazing results after implementing an SEO strategy."
  },
  {
    id: 21,
    text: "A search engine limits or broadens the visibility of content depending on what the user is looking for."
  },
  {
    id: 22,
    text: "So if you want to ensure your site is getting seen by the right people, investing in a good SEO company is wise."
  },
  {
    id: 23,
    text: (<>
      <h5><Link to={process.env.PUBLIC_URL + "/Social-Media-Marketing-agency-in-India"}>Social Media Marketing</Link></h5>
      <p>At our company, we are experts in social media marketing implementation. It means we provide full services for social media marketing, from content generation to creative ideation to social branding techniques.<br />
        We work across all popular social media platforms so that you can get the most out of your marketing campaigns.</p>
    </>)
  },
  {
    id: 24,
    text: (<>
      <h5><Link to={process.env.PUBLIC_URL + "/PPC-Services-India"}>Pay Per Click (PPC)</Link></h5>
      <p>We can help increase your brand's digital footprint with our Pay Per Click campaigns on Google and Bing. We'll work with you to sketch a plan and implement it accordingly. Once that's done, your campaign will be up and running!</p>
    </>)
  },
  {
    id: 25,
    text: (<>
      <h5>Email Marketing</h5>
      <p>Email marketing can be tricky. It's not as simple as just writing an email and sending it off. You must be strategic about whom you're reaching out to and what you're saying. A well-executed email marketing campaign can be a great way to connect with your audience and promote your business. But if you don't take the time to do it right, you could end up doing more harm than good.</p>
    </>)
  }
]

const SEO1 = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>
            SEO services in Hyderabad | Seo agency | Origin software
          </title>
          <link rel="canonical" href="./SEO-Services-in-Hyderabad" />
          <meta
            name="description"
            content="Origin software (SEO agency) offers the best SEO services in Hyderabad like On page and Off page optimization, link-building, keyword research, etc."
          />
          <meta name="keywords" content="Seo services in hyderabad" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Digital Marketing"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/SEO-Services-in-Hyderabad"}>SEO</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Social-Media-Marketing-agency-in-India"}>SMM</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/PPC-Services-India"}>PPC</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">SEO Services</h1>
                  <img className="img mb--20" src="../images/Slider/SEO.png" alt="SEO-Services-in-Hyderabad" />
                  <p>Origin Softwares is a trusted SEO services company in Hyderabad offering organic search engine optimization services in Hyderabad, India.<br /><br />
                    Hyderabad is a bustling hub for start-ups and global MNCs, and our SEO agency is proud to help enterprises of all sizes to stay at the top of the race. We use various SEO tips and tricks to give our clients the edge they need to succeed.<br />
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us </Link>
                  </p>

                  <div className="row mb--20">
                    {allData.slice(0, 9).map(item => (
                      <div className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row mb--20">
                    <h4>SEO Services</h4>
                    {allData.slice(9, 14).map(item => (
                      <div className="Box3 col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className='mb--20'>Technologies we Offer</h3>
                    <div><Technology /></div>
                  </div>

                  <div className="container mt-3">
                    <div className="row">
                      <h4 className="mb--20">Our SEO Process</h4>
                      <p>Get to know the business. Follow the keyword research process</p>
                      {jsonData1.services.map((service, index) => (
                        <div className="col-md-3">
                          <div className="Scard margin">
                            <a href={process.env.PUBLIC_URL + service.link}>{service.title}</a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <ul className="b">
                    <li>At Origin Softwares, we specialize in helping businesses achieve top ranking on major search engines like Google, Bing, and more. But we don't just stop there - we also help to generate leads and conversions from your website.</li><br />
                    <li>If you're ready to take your business to new heights, reach out today……………. for the best SEO services in Hyderabad.</li>
                  </ul>

                  <div className="row">
                    <h4>SEO Services in Hyderabad</h4>
                    <h6>Boost Your Business with Our Expertise</h6>
                    {allData.slice(14, 22).map(item => (
                      <div className="Box col-xl-6 col-lg-6">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className='mb--20'>Our Industries</h3>
                    <div><Industries /></div>
                  </div>

                  <h4 className="mb--20">Benefits of hiring an SEO services company in Hyderabad</h4>
                  <img className="thumbnail" src="../images/InfoGraphics/seo.png" alt="Thumbnail" />

                  <div className="row mb--20">
                    <div className="paper col-xl-12 col-lg-2">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <h5>Origin Softwares is the best SEO company in Hyderabad.</h5>
                          <p><ul className="b">
                            <li>We offer top-notch SEO services to businesses of all sizes, from small businesses to large corporations. Our SEO campaigns are executed with different SEO strategies and packages, so you can find a solution that fits your budget and needs.</li>
                            <li>We also have customized SEO solutions that can tailor to your specific requirements.</li>
                            <li>Each type of SEO plan will have different deliverables, but all plans will include monthly site audit reports, keyword ranking reports, conversion tracking reports, and event tracking reports.</li>
                            <li>As a digital marketing agency, we have experts passionate about helping brands get the most out of their social media campaigns. We are based in Hyderabad, India, and offer our services to brands worldwide. We specialize in campaign management and are dedicated to helping our clients get the most ROI from their digital marketing campaigns.</li></ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h4>Digital Marketing Services</h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b"><li><a href={process.env.PUBLIC_URL + services.link}>{services.title}</a></li></ul>
                      </div>
                    ))}
                  </div>

                  <div className="row mb--20">
                    {allData.slice(22, 25).map(item => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Accordion>
                    <h4 className="color">SEO FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="What is the average time for SEO to work?"
                      body={<>
                        <ul>
                          ► Professional services like those necessary for SEO can take longer to show results. Outcomes are never instant, and it can often take at least 3 to 6 months before you see any significant changes.<br />
                          ► It is because much work goes into making technical improvements, off-page developments, and on-page improvements to increase the quality of the web page overall. Your brand and services play a role in increasing credibility.<br />
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="2"
                      header="What are the best services from the SEO company in Hyderabad?"
                      body={<>
                        <ul>
                          ► Search engine optimization(SEO) is a process that helps a business get a higher ranking in search engines.<br />
                          ► When a new brand comes along, experienced SEO marketers can help identify the areas where the company should focus its attention to get better results—having someone who knows how to take care of the details can be a big help for launching a new website or product.<br /><br />
                          <h6>Site audit :</h6>
                          ► A website audit is a great way to understand what needs to be done before beginning a project. By auditing the site, you can understand all the technical aspects of the site and what on-page and off-page solutions need to be employed.<br /><br />
                          <h6>SEO strategy:</h6>
                          ► The SEO specialist does much research to see where your website falls compared to your competitors. If there is an enormous gap, they work to improve your website so that it can better compete in the market.<br /><br />
                          <h6>Implementation:</h6>
                          ► The top SEO agencies in Hyderabad know that site auditing is key to successful SEO implementation. Once the problems are identified during the audit, they get to work quickly to solve them. <br />
                          ► This way, your site can start ranking higher in search results and attracting more traffic.<br /><br />
                          <h6>Fine Tuning:</h6>
                          ► SERP analysis can help get a website trending. The SEO company in Hyderabad can help a website rapidly rise to the top of the industry by technically approaching the google algorithm of the search engine with new technologies and updates.<br />
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="3"
                      header="Why would I need SEO services?"
                      body={<>
                        <ul>
                          ► SEO services will help increase traffic to your site. You will bring in new customers that you wouldn't have been able to reach otherwise. The more people you bring in, the more money you will make. Simple enough.<br />
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="4"
                      header="Does SEO matter?"
                      body={<>
                        <ul>
                          ► In short, yes, it does. It matters a lot. Many fresh businesses are stressing over their site design, content, product features, and tone.<br />
                          But they neglect the most important of all: search engine optimization. According to a study, 90% of Internet users use search engine results to find a product or a service online.
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="5"
                      header="What does a professional SEO Agency do?"
                      body={<>
                        <ul>
                          ► The primary aim of professional SEO agencies is to increase a website's visibility in search engines like Google. Such agencies use various techniques to improve search rankings, including link building, keyword optimization, Web development, and web design enhancements. Some agencies focus on specific target markets, such as local businesses, while others offer general optimization.<br />
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="6"
                      header="How to know if the SEO Agency is real with services?"
                      body={<>
                        <ul>
                          ► Among marketing agencies in Hyderabad, When looking for an SEO agency to hire, the first thing to remember is that the digital marketing company should have extensive experience in the field and a certified team of digital marketing professionals. <br />
                        </ul>
                      </>}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default SEO1;
// 690