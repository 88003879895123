import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from '../component/Technologies/Technology';
import Industries from "../component/Industries/Industries";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  "services": [
    { title: "Software Consulting", link: "/Software-Consulting" },
    { title: "Custom Software Development", link: "/Custom-Software-Development-Company-in-India" },
    { title: "Software Development Outsourcing", link: "/Software-Development-Outsourcing" },
    { title: "Software Product Development", link: "/Software-Product-Development" },
    { title: "Software Development Automation", link: "/Software-Development-Automation" },
    { title: "Software Maintenance and support", link: "/Software-Maintenance-and-Support" }
  ]
};

const allData = [
  {
    id: 1,
    text: (
      <>
        <h6> Origin Softwares as a Software Development Outsourcing Company </h6>
        <p>Origin is a software development outsourcing company with over 10 years of experience. We have development centers in Hyderabad and the EU. We cater to both software product companies and non-IT enterprises. Our quality management system is certified by ISO 9001, which means we can deliver projects on time, on budget, and to our customers' quality expectations.</p>
      </>
    )
  },
  {
    id: 2,
    text: (
      <>
        <h6>Benefits of outsourcing software development </h6><br />
        <p>There are many benefits to outsourcing software development, including increased quality and speed and decreased costs. According to statistics, more than 50% of Fortune 500 companies outsource their software development.</p>
      </>
    )
  },
  {
    id: 3,
    text: (
      <>
        <h5>Staff Augmentation</h5>
        <p>Staff augmentation is when outsourced developers work remotely with your in-house team and are managed by you directly. The key benefit of this model is filling in the skill and resource gaps within your development team.</p>
      </>
    )
  },
  {
    id: 4,
    text: (
      <>
        <h5>A Dedicated Team</h5>
        <p>A dedicated team is when an outsourced team covers a part of the development project and is managed by a vendor's PM, who regularly reports to you on the progress. The key benefit of this model is expanding software development capabilities and minimizing management efforts.</p>
      </>
    )
  },
  {
    id: 5,
    text: (
      <>
        <h5>Full Process Outsourcing</h5>
        <p>Full process outsourcing is when a vendor sets up the development process and takes full responsibility. This model is beneficial if you want to minimize risks and costs associated with the project.</p>
      </>
    )
  }
]

const SoftwareDevelopmentOutsourcing = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>
            Software Development Outsourcing Services | Origin software
          </title>
          <link rel="canonical" href="./Software-Development-Outsourcing" />
          <meta
            name="description"
            content="Origin software provides the most comprehensive Software Development Outsourcing Services to companies with all software development needs."
          />
          <meta name="keywords" content="Software development outsourcing" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className=" section-padding">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Software Development"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Consulting"}> Software Consulting</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Custom-Software-Development-Company-in-India"}>Custom Software Development</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Development-Outsourcing"}> Software Development Outsourcing</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Product-Development"}> Software Product Development</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Development-Automation"}> Software Development Automation</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Software-Maintenance-and-Support"}> Software Maintenance and support</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Software Development Outsourcing </h1>
                  <div>
                    <img className="img mb--20" src="../images/Software Development Outsourcing.png" alt="Software-Development-Outsourcing" />
                  </div>
                  <p>Software development outsourcing involves assigning responsibility for certain software development activities to an external vendor. It can help businesses cover a lack of internal resources or expertise in business growth or digital transformation initiatives. According to recent predictions, the software development outsourcing market is expected to grow by around 98 billion dollars during 2020-2024, with a CAGR (Compound Annual Growth Rate) of 5%.</p>

                  <div className="row mb--20">
                    {allData.slice(0, 2).map(item => (
                      <div key={item.id} className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row mb--20">
                    <h4>Outsourcing options</h4>
                    <p>Origin Softwares offers 3 main outsourcing models: Staff Augmentation, Dedicated Team, and FullProcess Outsourcing.</p>
                    {allData.slice(2, 5).map(item => (
                      <div key={item.id} className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small"> Get a Quote</Link>

                  <h4>Our Industry's</h4>
                  <p className="mb--20">We develop industry-specific software for many Industries. See our projects in the particular industry you're interested in as a business holder.</p>

                  <div className="row">
                    <h4>Software for Different Industries</h4>
                    <div><Industries /></div>
                  </div>

                  <div className="row paper">
                    <h4 className="title">Our Domain expertise:</h4>
                    <div className="col-xl-6 col-lg-5">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          you can find the most popular software types we offer.
                          <ul className="b">
                          <li>ERP</li>
                          <li>CRM</li>
                          <li>HR management</li>
                          <li>Financial management</li>
                          <li>Supply chain management</li>
                          <li>Asset management</li>
                          <li>Data Analytics</li>
                          <li>Ecommerce Solutions</li>
                          <li>B2B and B2C portals</li>
                          <li>eLearning</li>
                          <li>Fleet management</li></ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-5">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left shadow-box mb--30">
                          <img className="thumbnail" src="../images/InfoGraphics/Our-Domain.png" alt="Thumbnail" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h4>Our Testimony</h4>
                    <div><TestimonialTwo /></div>
                  </div>

                  <h4 className="mb--20">Different cooperation formats </h4>
                  <p>We offer 2 different cooperation formats:<br />
                    1. For new applications,we deliver an application in <span style={{ fontWeight: "bold" }}>3-4 months</span> and then proceed with adding new features <span style={{ fontWeight: "bold" }}>every 2-6 weeks.</span><br />
                    2. We can join your project at any stage for existing software to support and evolve the codebase.</p>

                  <div className="row">
                    <h4>Technologies we deal with</h4>
                    <div><Technology /></div>
                  </div>

                    <div className="row">
                    <h4 className="mb--20">Services we Offer</h4>
                    {jsonData.services.map((service, index) => (
                      <div key={index} className="col-xl-6 col-lg-2">
                          <ul className='b'><li><a href={process.env.PUBLIC_URL + service.link}>{service.title}</a></li></ul>
                      </div>
                    ))}
                  </div>

                  <h4 className="mb--20">Contact us</h4>
                  <p>If you're interested in learning more about our software development outsourcing service, please contact us today to discuss your specific needs.<br></br>
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link></p>
                  <Accordion>
                    <h4 className="color">Software Developmet Outsourcing FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header=" How to Assure effective Software Outsourcing?"
                      body={
                        <>
                          <ul>
                            <p> When looking to outsource your software development, there are a few key things to keep in mind to ensure efficiency.<br />
                              ● First, testing a vendor's expertise in practice is important by launching a small pilot project or PoC. It will give you an objective understanding of their competencies.<br />
                              ● Second, sign a service level agreement with the vendor that outlines expectations for timelines, deliverables, quality requirements, and risks.<br />
                              ● And third, decide on a KPI system to monitor the project's progress without being over-involved in the process.<br />
                              ● By following these steps, you can be confident you're getting the most efficient software outsourcing possible.<br />
                              ● Before beginning to work with a vendor, it is important to establish clear communication channels and dedicate the necessary resources to the project. <br />
                              ● It will help ensure everyone is on the same page and avoid any potential misunderstandings further down the line.<br />
                              ● It is also crucial to control outcomes and protect your business interests.<br />
                              ● Signing a non-disclosure agreement (NDA) with your vendor will help to ensure that your confidential information and intellectual property rights are protected.<br />
                              ● You should also check that they have the relevant security measures to protect your data, such as IDS/IPS, DLP, SIEM, etc.</p>
                          </ul>
                        </>
                      }
                    />
                    <AccordionItem
                      eventKey="2"
                      header="What the procedure of our software development?"
                      body={
                        <>
                          <ul>
                            <p>At our company, we offer a comprehensive outsourcing service that takes your project from start to finish.<br />
                              <h6>Steps to take for outsourcing services </h6>
                              ► We kick things off with an analysis of your initial request, and then we move into discovery mode to assess your software development needs and craft a service plan.<br />
                              ► From there, we negotiate an SLA and transfer knowledge and responsibility to our team.<br />
                              ► Once service delivery begins, we continue to evolve the service as needed to ensure your ongoing satisfaction.</p>
                          </ul>
                        </>
                      }
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default SoftwareDevelopmentOutsourcing;