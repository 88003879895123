import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const jsonData = {
  "services": [
    { title: "Android Apps", link: "/Andriod" },
    { title: "iOS Apps", link: "/IOS" },
    { title: "React Native Mobile App", link: "/React-Native-App-Development-Company" },
    { title: "Xamarin Apps", link: "/Xamarin-App-Development" },
    { title: "Hybrid Apps", link: "/Hybrid-App-Development" },
    { title: "Mobile App Design", link: "/Mobile-App-Design-Services" },
    { title: "Mobile Ecommerce Apps", link: "/Mobile-Ecommerce-App-Development" }
  ]
}

const allData = [
  {
    id: 1,
    text: "Origin Softwares is a company that provides mobile eCommerce app development services. They have successfully implemented solutions for eBay and Walmart, and are now ready to work on your project."
  },
  {
    id: 2,
    text: "Services they offer include business analysis and consulting, mobile UX and UI design, mobile development (+web), and testing."
  },
  {
    id: 3,
    text: "Their team is dedicated to providing quality services that will boost mobile conversions, facilitate pre-purchase research, and increase customer satisfaction."
  },
  {
    id: 4,
    text: "There are a few different ways that stores can help increase conversions on mobile. One way is by complementing your web store with a mobile app."
  },
  {
    id: 5,
    text: "Another way is by using high-quality visuals, 360° product preview, and AR try-on to increase purchase confidence. You can also retain customers by offering them digital coupons, bonus point accounts, and maps with chain- and/or partner stores."
  },
  {
    id: 6,
    text: "Finally, you can guide clients through product installation/ maintenance with videos or AR and support them via chatbots/messaging."
  },
  {
    id: 7,
    text: "You've decided that you want to develop a mobile eCommerce app, but you're not sure where to start."
  },
  {
    id: 8,
    text: "Do you want an all-in-one solution that takes care of the entire customer journey or something that only supports specific stages?"
  },
  {
    id: 9,
    text: "Origin Softwares can create either type of solution, depending on your needs. And if you only need a smaller app for specific purposes, we can deliver that, too."
  },
  {
    id: 10,
    text: "You get to choose how feature-packed your app will be! Our Approach to Mobile eCommerce Development."
  },
  {
    id: 11,
    text: (<>
      <h5>Data security</h5>
      <p>At Origin Softwares, we understand that data security is paramount for any eCommerce solution. That's why we employ ISO 27001-approved security processes to guarantee the full integrity of protected data.</p>
    </>)
  },
  {
    id: 12,
    text: (<>
      <h5>Web + mobile</h5>
      <p>We also make sure that our web and mobile solutions are aligned in functionality and design. If you don't have a web solution yet, we're ready to develop it from scratch.</p>
    </>)
  },
  {
    id: 13,
    text: (<>
      <h5>Seamless Integration</h5>
      <p>Finally, we know that seamless integration is key to a successful eCommerce operation. That's why we build robust APIs for smooth and fast data exchange within ecosystems.</p>
    </>)
  },
  {
    id: 14,
    text: "A typical mobile store takes around 1,800 hours to implement, with key features like a catalog, account management, a shopping cart, a wishlist, and delivery tracking."
  },
  {
    id: 15,
    text: "Adding secure online payment modules like Visa, MasterCard, PayPal, and Stripe adds an additional 80 hours of development time. Finally, loyalty features like coupons, partner store maps, and bonuses add another 160 hours to the project."
  }
]

const MobileEcommerceApps = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>Mobile Ecommerce App Development | Origin softwares</title>
          <link rel="canonical" href="./Mobile-Ecommerce-App-Development" />
          <meta
            name="description"
            content="Origin softwares is a mobile application development company that builds innovative e-commerce apps for your growing business."
          />
          <meta name="keywords" content="Mmobile ecommerce app development" />
        </Helmet>
        <main className="main wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Mobile Apps"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Andriod"}>Android Apps</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/IOS"}>iOS Apps</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/React-Native-App-Development-Company"}>React Native Mobile App</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Xamarin-App-Development"}>Xamarin Apps</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Hybrid-App-Development"}>Hybrid Apps</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Mobile-App-Design-Services"}>Mobile App Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Mobile-Ecommerce-App-Development"}>Mobile Ecommerce Apps</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Mobile Ecommerce App Development</h1>
                  <img className="img mb--20" src="../images/Slider/Mobile Ecommerce App Development.png" alt="Mobile-Ecommerce-App-Development" />
                  <p>Looking to boost conversions, loyalty, and brand strength?<br />
                    Origin Softwares has got you covered. <span style={{ fontWeight: "bold" }} >With over 9 years of experience in mobile eCommerce app development</span> and <span style={{ fontWeight: "bold" }}>6 years in eCommerce IT,</span> our team is expert in delivering top-notch mobile commerce solutions.<br />
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link></p>

                  <div className="row mb--20">
                    <h4>Scope of Our Mobile eCommerce App Development Services</h4>
                    {allData.slice(0, 3).map(item => (
                      <div className="Box1 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3 className='mb--20'>Our Industries</h3>
                    <div><Industries /></div>
                  </div>

                  <div className="row mb--20">
                    <h4>Mobile eCommerce Apps We Develop</h4>
                    {allData.slice(3, 10).map(item => (
                      <div className="Box3 col-xl-6 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div>
                    <h4 className="mb--20">Technologies We Use</h4>
                    <Technology />
                  </div>

                  <div className="row">
                    {allData.slice(10, 13).map(item => (
                      <div className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4>Sample Mobile Commerce Development</h4>
                    {allData.slice(13, 15).map(item => (
                      <div className="Box col-xl-6 col-lg-2">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h4 className="mb--20">Mobile App Services</h4>
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className="b"><li><a href={process.env.PUBLIC_URL + services.link}>{services.title}</a></li></ul>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <div className="paper col-xl-12 col-lg-2">
                      <div className="why-choose-us">
                        <div className="section-heading heading-left">
                          <h4>Choose Your Mobile Commerce Development Option</h4>
                          <p><ul className="b">
                            <h5>Mobile-only commerce solution implementation</h5>
                            <li>We can help you with everything from feature prioritization to ROI calculation, and we're experts in mobile tech stack definition and back-end architecture design.</li>
                            <li>Plus, our research-based UX and on-brand UI design and implementation will make your app look amazing.</li>
                            <li>We can develop native/cross-platform mobile apps and PWAs, and we're experienced in integrating with all kinds of back-office software and payment systems.</li>
                            <li>And once your app is ready, we can help you get it distributed to all the major app stores. Plus, we offer continuous maintenance and support so you're never left stranded.</li><br />
                            <h5>Mobile + web commerce solution implementation</h5>
                            <li>We provide a full range of eCommerce solutions, from conceptualization to development and integration.</li>
                            <li>We'll work with you to choose the right tech stack for your budget and needs, and develop a solution that meets your specific requirements.</li>
                            <li>Our team includes experts in mobile eCommerce app development and web app development, UX and UI design, integration, and maintenance. We're here to support you every step of the way.</li>
                            <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link>
                          </ul></p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default MobileEcommerceApps;
// 366