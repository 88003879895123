import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Technology from "../component/Technologies/Technology.js";
import Industries from "../component/Industries/Industries.js";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className="color1">{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  )
}

const jsonData = {
  "services": [
    { title: "UI Design", link: "/UI-Design-Services" },
    { title: "UX Design", link: "/UX-Design-Services" },
    { title: "Responsive Web Design", link: "/Responsive-Web-Design-Services" },
    { title: "Responsive Web App Design", link: "/Responsive-Web-Application" },
    { title: "Website Redesign", link: "/Website-Redesign-Services" }
  ]
}

const allData = [
  {
    id: 1,
    text: "One-off responsive design from scratch to help you quickly and easily adopt the app and improve customer acquisition."
  },
  {
    id: 2,
    text: "Responsive redesign to improve responsiveness, fix UI and UX flaws, and support your team."
  },
  {
    id: 3,
    text: "Continuous responsive design to support your ever-evolving web app."
  }
]


const ResponsiveWebAppDesign = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>
            Design Responsive Web Application Design | Origin software
          </title>
          <link rel="canonical" href="./Responsive-Web-Application" />
          <meta
            name="description"
            content="Bring web applications to life with Origin software; the Responsive Web Design application framework. contact us today."
          />
          <meta name="keywords" content="responsive web application" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher />
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                    <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="Website Design"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/UI-Design-Services"}>UI Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/UX-Design-Services"}> UX Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Responsive-Web-Design-Services"}> Responsive Web Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Responsive-Web-Application"}> Responsive Web App Design</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Website-Redesign-Services"}> Website Redesign</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Responsive Web Application Design</h1>
                  <img className="img mb--20" src="../images/Slider/Responsive Web Application Design.png" alt="Responsive-Web-Application" />
                  <p> Responsive Web Application Design: At Origin Softwares, we don't just aim to create responsive web apps - we want to create mobile-first web experiences that are tailored to the specific needs of mobile users.<br /><br />
                    This way, your app will be easy to use and adopt, and users will be more likely to stick with it.</p>

                  <div className="row mb--20">
                    <h4>Our Responsive Web App Design Service Options</h4>
                    {allData.slice(0, 3).map(item => (
                      <div key={item} className="Box3 col-xl-4 col-lg-4">
                        <div className="why-choose-us">
                          <div className="section-heading heading-left">
                            <p>{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    {jsonData.services.map((services, index) => (
                      <div className=" col-xl-6 col-lg-2">
                        <ul className='b'><li><a href={process.env.PUBLIC_URL + services.link}>{services.title}</a></li></ul>
                      </div>
                    ))}
                  </div>

                  <div className="row">
                    <h3>Softwares Technologies</h3>
                    <div><Technology /></div>
                  </div>

                  <h5>Make a Secure Investment in Responsive Web App Design</h5>
                  <p>You don't want to lose nearly half of your potential users because your app didn't adapt to their mobile devices!</p>

                  <div className="row">
                    <h3>Our Industries</h3>
                    <div><Industries /></div>
                  </div>

                  <h5>Don’t Let Half of Your Userbase Leave!</h5>
                  <p>By investing in responsive web application design, you can make sure that your app is accessible and user-friendly for everyone.<br />
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small" > Contact Us</Link></p>

                  <Accordion>
                    <h4 className="color">Responsive Web App Design FAQ's</h4>
                    <AccordionItem
                      eventKey="1"
                      header="Offer Your Mobile Audience Without a Native App?"
                      body={<>
                        <ul>
                          <p>► Our responsive web app design services ensure that your app will be easy to navigate, fast-loading, and high-interactive regardless of the device in use.<br />
                            ► So if you want to cater to your mobile audience without creating a native app, Origin Softwares is the perfect partner for you.</p>
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="2"
                      header="Why Origin Softwares?"
                      body={<>
                        <ul>
                          <p>► Origin Softwares has over 9 years of experience in the IT industry.<br></br>
                            ► Our in-house UI and UX designers have 9 years of experience and are experts in mobile software development. We focus on agile web app development that is responsive and user-friendly.</p>
                        </ul>
                      </>}
                    />
                    <AccordionItem
                      eventKey="3"
                      header="Why does Responsive Web App Design Matter?"
                      body={<>
                        <ul>
                          <p>► Our responsive web app design services are important because poor mobile UI and UX can lead to users abandoning your app, costing you $2.6 billion in ROI annually. <br />
                            ► A great mobile design, on the other hand, will keep users coming back to your app time and time again.</p>
                        </ul>
                      </>}
                    />
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default ResponsiveWebAppDesign;