import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import GoogleTagManager from "./GTM";

const AccordionItem = ({ eventKey, header, body }) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{<div className='color1'>{header}</div>}</Accordion.Header>
      <Accordion.Body>{body}</Accordion.Body>
    </Accordion.Item>
  );
};

const ExecutiveSearch = () => {
  <GoogleTagManager gtmId="GTM-WV4R3LC" />
  return (
    <>
      <div>
        <Helmet>
          <title>
            Global Executive Research Services Firm | Origin Software
          </title>
          <link
            rel="canonical"
            href="https://www.originsoftwares.com/Executive-Search"
          />
          <meta
            name="description"
            content="Origin Software is a global executive research services firm that provides strategic insights to its clients by combining the best practices of consulting."
          />
          <meta name="keywords" content="EXECUTIVE RESEARCH" />
        </Helmet>
        <main className="main-wrapper">
          <HeaderOne />
          <ColorSwitcher/>
          <div className="section-padding-equal">
            <div className="container">
              <div className="row row-40">
                <div className="col-lg-3 why-choose-us">
                  <div className="sidebar-fixed">
                  <Accordion defaultActiveKey="1">
                      <AccordionItem
                        eventKey="1"
                        header="IT Staffing"
                        body={<>
                          <ul className="a">
                            <li><Link to={process.env.PUBLIC_URL + "/Recruitment-Services"}> Recruitment</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Staffing-Resources"}>Resourses</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Contract-Staffing-Services"}> Contract Staffing</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Direct-Hire"}> Direct Hire</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Executive-Search"}> Executive Search</Link></li><br />
                            <li><Link to={process.env.PUBLIC_URL + "/Offshore-Staffing"} > Offshore Staffing</Link></li>
                          </ul>
                        </>}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="title-BC col-lg-9">
                  <h1 className="color">Executive Research</h1>
                  <img className="img mb--20" src="../images/Slider/Executive search.png" alt="Executive-Search "/>
                  <p>Origin Softwares always uses its original executive research methods for every project. But if you want, you can buy extra in-depth research about your choice of target companies too.<br/><br/>
                    This research comes in the form of a spreadsheet that has all the info you need, like the person's name and contact information, what company they work for, what their title is, and any diversity data points you requested.<br/><br/>
                    Also, new research is created for every new position that gets requisitioned. We do this because it's the best way to identify the right pool of professionals who fit the criteria for your available positions.<br/><br/>
                    This, combined with our expert internet sourcing techniques, allows us to efficiently provide our clients with the most accurate and up-to-date long and short lists of qualified individuals at competitor firms.<br/>
                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-small">Contact Us</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <FooterOne />
        </main>
      </div>
    </>
  );
};

export default ExecutiveSearch;
// 113